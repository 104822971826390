import React, {PureComponent} from "react";
import {Box, Card, Typography} from "@mui/material";
import {StyledBoxColumn} from "../shared/StyledComponents";
import {PD_XLG} from "../shared/dimens";

import {Game} from "./types";

export type GameCardProps = {
  style?: any,
  game: Game,
  children?: any,
  onClick: () => void,
}

export class GameCard extends PureComponent<GameCardProps> {

  render() {
    const game = this.props.game;
    return <Card style={{borderRadius: 12, cursor: "pointer", ...this.props.style}} onClick={() => this.props.onClick()}>
      <Box style={{display: "flex", flexDirection: "column", width: "100%", position: "relative"}}>
        <StyledBoxColumn style={{background: game.color, padding: PD_XLG, alignItems: "center", gap: PD_XLG}}>
          <img src={game.icon} style={{width: 96}}/>
          <Typography style={{fontFamily: "Gabarito, sans-serif", fontSize: "160%", fontWeight: "bold"}}>{game.name}</Typography>
        </StyledBoxColumn>
        {this.props.children}
      </Box>
    </Card>
  }
}