import {GameLevel, Score, Scores} from "../types";
import {DateUtil, MINUTE_IN_MILLIS} from "../../shared/date_util";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledContainer} from "../../shared/StyledComponents";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import {InfoCard} from "../InfoCard";
import ReactECharts, {EChartsOption} from "echarts-for-react";
import {BaseApp, DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN} from "../../shared/BaseApp";
import {ScoreHistoryFragment} from "./ScoreHistoryFragment";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {DAYS_HISTORY, GAMES} from "../Games";
import {COLOR_PRIMARY} from "../App";
import {BarChartOutlined, ExpandMoreOutlined} from "@mui/icons-material";
import {EmptyConfig} from "../../shared/types";

export type GameStatsFragmentProps = PageFragmentProps & {
  gameId: string,
}

type Stats = {
  totalGames?: number,
  longestGame?: string,
  bestPoints?: string,
  averagePoints?: string,
  scoreHistoryOption?: EChartsOption,
}

type GameStatsFragmentState = PageFragmentState & {
  statsMap: Map<GameLevel, Stats>,
}

export class GameStatsFragment extends PageFragment<GameStatsFragmentProps, GameStatsFragmentState> {

  private readonly scores = Scores.getInstance();

  getTitle(): string {
    return "";
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const game = GAMES.find(game => game.id === this.props.gameId);
    const scores = (await this.scores.getOrLoadListItems()).filter(score => score.gameId === this.props.gameId);
    const statsMap = new Map<GameLevel, Stats>();
    for (const level of game.levels) {
      const stats = await this.getStats(scores.filter(score => score.level === level.name));
      if (!(stats.totalGames > 0)) {
        continue;
      }
      statsMap.set(level, stats);
    }
    this.setState({
      statsMap: statsMap,
    });
  }

  private async getStats(scores: Score[]): Promise<Stats> {
    return {
      totalGames: scores.length,
      longestGame: ((scores.reduce((previousValue, currentValue) => {
        const duration = currentValue.duration;
        return previousValue > duration ? previousValue : duration;
      }, 0) || 0) / MINUTE_IN_MILLIS).toFixed(0) + " m",
      bestPoints: (scores.reduce((previousValue, currentValue) => {
        const points = currentValue.points;
        return previousValue > points ? previousValue : points;
      }, 0) || 0).toFixed(0) + " pts",
      averagePoints: ((scores.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.points;
      }, 0) || 0) / (scores.length || 1)).toFixed(0) + " pts",
      scoreHistoryOption: await this.getScoreHistoryOption(scores),
    }
  }

  componentDidUpdate(prevProps: Readonly<GameStatsFragmentProps>, prevState: Readonly<GameStatsFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevProps.gameId !== this.props.gameId) {
      this.reload();
    }
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      title: "Here come the numbers",
      text: "There's nothing here yet, but you'll see your performance numbers here when you start playing.",
      iconType: BarChartOutlined,
    };
  }

  renderContent(): ReactElement {
    const statsMap = this.state.statsMap;
    if (statsMap.size <= 0) {
      return this.renderEmpty();
    }
    return <StyledContainer style={{gap: 0}}>
      {[...statsMap.entries()].map(entry => {
        const level = entry[0];
        const stats = entry[1];
        return this.renderStats(level, stats);
      })}
    </StyledContainer>
  }

  renderStats(level: GameLevel, stats: Stats): ReactElement {
    return <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreOutlined/>}>
        <Typography variant="h6">{level.text}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <StyledBoxColumn>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InfoCard variant="contained" title="Solved">
                <Typography variant="h4">{stats.totalGames}</Typography>
              </InfoCard>
            </Grid>
            <Grid item xs={6}>
              <InfoCard variant="contained" title="Longest">
                <Typography variant="h4">{stats.longestGame}</Typography>
              </InfoCard>
            </Grid>
            <Grid item xs={6}>
              <InfoCard variant="contained" title="Best">
                <Typography variant="h4">{stats.bestPoints}</Typography>
              </InfoCard>
            </Grid>
            <Grid item xs={6}>
              <InfoCard variant="contained" title="Average">
                <Typography variant="h4">{stats.averagePoints}</Typography>
              </InfoCard>
            </Grid>
          </Grid>
          <InfoCard
            title="Score history"
            renderToolbar={() => <Button onClick={() => this.showScoreHistory()}>View history</Button>}
          >
            <ReactECharts option={stats.scoreHistoryOption}/>
          </InfoCard>
        </StyledBoxColumn>
      </AccordionDetails>
    </Accordion>;
  }

  private showScoreHistory() {
    BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, () => <ScoreHistoryFragment/>);
  }

  private async getScoreHistoryOption(scores: Score[]): Promise<EChartsOption> {
    const map = new Map<string, Score[]>();
    for (const score of scores) {
      const ds = DateUtil.toDatestamp(new Date(score.created));
      let list = map.get(ds);
      if (!list) {
        list = [];
        map.set(ds, list);
      }
      list.push(score);
    }
    const xData: string[] = [];
    const yData: any[] = [];
    for (let i = 0; i < DAYS_HISTORY; i++) {
      const date = new Date();
      date.setDate(date.getDate() - (DAYS_HISTORY - 1 - i));
      const ds = DateUtil.toDatestamp(date);
      xData.push(date.toLocaleDateString());
      const averageScore = Math.floor((scores.filter(score => score.ds === ds).reduce((previousValue, currentValue) => {
        return previousValue + currentValue.points;
      }, 0) || 0) / (scores.length || 1));
      yData.push({
        value: averageScore,
        itemStyle: {color: COLOR_PRIMARY},
      });
    }
    return {
      xAxis: {
        data: xData,
      },
      yAxis: {},
      series: [
        {
          type: 'bar',
          data: yData,
        }
      ]
    };
  }
}