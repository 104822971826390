import {Connections} from "./shared/connect/types";
import {Score, ScoreItems, Scores} from "./types";
import {dbRef_getVal} from "../shared/database";
import {getMemberAuth} from "../shared/auth";
import {JSON_OBJECT} from "../common/json/helpers";

export class Api {

  private static instance;

  private readonly memberAuth = getMemberAuth();
  private readonly scores = Scores.getInstance();
  private readonly connections = Connections.getInstance();

  static getInstance(): Api {
    if (!this.instance) {
      this.instance = new Api();
    }
    return this.instance;
  }

  private constructor() {
  }

  async getScoreItems(): Promise<ScoreItems> {
    const scoreItems = new ScoreItems();
    const scores = await this.scores.getOrLoadListItems();
    scores.forEach(score => scoreItems.add(this.memberAuth.member.user, score));
    for (const contact of (await this.connections.getOrLoadListItems())) {
      const val = await dbRef_getVal("scores/" + contact.contactId);
      if (val) {
        for (const key in val) {
          const value = val[key];
          const score = JSON_OBJECT.deserializeObject(value, Score);
          scoreItems.add(contact.user, score);
        }
      }
    }
    return scoreItems;
  }

  async setScore(score: Score): Promise<void> {
    await this.scores.addListItem(score);
  }
}
