import {GameBoard, GameConfig, GameData, GamePlay, GamePlayKey, GameSetup} from "../types";
import {v4 as uuid} from "uuid";

export const JUMBLED_MIN_WORD_LENGTH = 3;
const JUMBLED_LETTERS_PER_SLOT = 6;

export class JumbledLayout {
  constructor(readonly level: JumbledDifficultyLevel, readonly board: string[], readonly words: string[]) {
  }

  getWinCount(): number {
    return Math.floor(this.level.foundTotalWinRatio * this.words.length);
  }
}

export class JumbledDifficultyLevel {

  static readonly VALUES = new Array<JumbledDifficultyLevel>(3);
  static readonly EASY = JumbledDifficultyLevel.VALUES[0] = new JumbledDifficultyLevel("easy", "Easy", "Easy desc", 4, "games/jumbled/layout-4x4.txt", 0.5);
  static readonly MEDIUM = JumbledDifficultyLevel.VALUES[1] = new JumbledDifficultyLevel("medium", "Medium", "Medium desc", 5, "games/jumbled/layout-5x5.txt", 0.5);
  static readonly HARD = JumbledDifficultyLevel.VALUES[2] = new JumbledDifficultyLevel("hard", "Hard", "Hard desc", 6, "games/jumbled/layout-6x6.txt", 0.5);

  constructor(readonly name: string, readonly text: string, readonly description: string, readonly gridSize: number, readonly filename: string, readonly foundTotalWinRatio: number) {
  }
}

export class JumbledGameConfig extends GameConfig {

  constructor(readonly level: JumbledDifficultyLevel) {
    super();
  }
}

export class JumbledGameSetup extends GameSetup {

  constructor(key: GamePlayKey) {
    super(key);
  }
}

export class JumbledGameData extends GameData<JumbledGameBoard> {

  static async create(config: JumbledGameConfig): Promise<JumbledGameData> {
    const loader = new JumbledGamesLoader();
    const layout = await loader.getRandomGame(config.level);
    const setup = new JumbledGameSetup(new GamePlayKey("jumbled", uuid()));
    const now = Date.now();
    return new JumbledGameData(new GamePlay(setup, now, 0, 0, 0, new JumbledGameBoard(config.level, layout).toJSON()));
  }
}

export class JumbledGamesLoader {

  private readonly lettersMap = new Map<JumbledDifficultyLevel, string[][]>();

  constructor() {
  }

  async load(level: JumbledDifficultyLevel): Promise<string[][]> {
    const gridSize = level.gridSize;
    let letters: string[][] = this.lettersMap.get(level);
    if (!letters) {
      letters = [];
      const lines = (await fetch("/" + level.filename).then(result => result.text())).split("\n");
      for (let i = 0; i < gridSize * gridSize; i++) {
        letters.push(lines[i].split(""));
      }
      this.lettersMap.set(level, letters);
    }
    return letters;
  }

  async getRandomGame(level: JumbledDifficultyLevel): Promise<JumbledLayout> {
    const letters = await this.load(level);
    const gridSize = level.gridSize;
    const board: string[] = new Array<string>(gridSize * gridSize);
    const unused: number[] = [];
    for (let i = 0; i < board.length; i++) {
      unused.push(i);
    }
    for (let i = 0; i < board.length; i++) {
      const use = unused.splice(Math.floor(unused.length * Math.random()), 1)[0];
      board[i] = letters[use][Math.floor(JUMBLED_LETTERS_PER_SLOT * Math.random())];
    }
    return new JumbledLayout(level, board, await this.findWords(board, gridSize, gridSize));
  }

  async findWords(board: string[], rows: number, cols: number): Promise<string[]> {
    const set: string[] = [];
    const words: string[] = [];
    const lines = (await fetch("/assets/dict/all-words.txt").then(result => result.text())).split("\n");

    for (const line of lines) {
      if (line.length < JUMBLED_MIN_WORD_LENGTH) {
        continue;
      }
      words.push(line);
    }
    for (let y = 0; y < rows; y++) {
      for (let x = 0; x < cols; x++) {
        const current: string[] = new Array<string>(board.length);
        const used: boolean[] = new Array<boolean>(board.length);
        this.findWordsInternal(board, rows, cols, words, 0, words.length, x, y, current, 0, used, set);
      }
    }
    set.sort();
    //console.log("words: " + set);
    return set;
  }

  private findWordsInternal(board: string[], rows: number, cols: number, words: string[], startAt: number, endAt: number, x: number, y: number, current: string[], length: number, used: Array<boolean>, set: Array<string>) {
    if (!this.isInRangeAndUnused(rows, cols, x, y, used)) {
      return;
    }
    const index: number = cols * y + x;
    const c: string = board[index];
    const range: number[] = this.findNext(c, length, words, startAt, endAt);
    if (!range) {
      return;
    }
    startAt = range[0];
    endAt = range[1];
    current[length++] = c;
    const word: string = current.slice(0, length).join("");
    if (words.at(startAt) === word) {
      if (set.findIndex(w => w === word) < 0) {
        set.push(word);
      }
    }
    used[index] = true;
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x - 1, y - 1, current, length, used, set);
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x, y - 1, current, length, used, set);
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x + 1, y - 1, current, length, used, set);
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x - 1, y, current, length, used, set);
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x + 1, y, current, length, used, set);
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x - 1, y + 1, current, length, used, set);
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x, y + 1, current, length, used, set);
    this.findWordsInternal(board, rows, cols, words, startAt, endAt, x + 1, y + 1, current, length, used, set);
    used[index] = false;
  }

  private findNext(c: string, index: number, words: string[], startAt: number, endAt: number): number[] {
    let word: string;
    while (startAt < endAt && (index >= (word = words[startAt]).length || word.charAt(index) < c)) {
      startAt++;
    }
    while (endAt > startAt && (index >= (word = words[endAt - 1]).length || word.charAt(index) > c)) {
      endAt--;
    }
    if (startAt >= endAt) {
      return null;
    }
    return [startAt, endAt];
  }

  private isInRangeAndUnused(rows: number, cols: number, x: number, y: number, used: Array<boolean>): boolean {
    return x >= 0 && x < cols && y >= 0 && y < rows && !used[y * cols + x];
  }
}

export class JumbledGameBoard extends GameBoard {

  // Max duration to earn time-based points
  private static readonly POINTS_MAX_DURATION = 10 * 60 * 1000; // 10 mins
  // Max words to earn total words-based points
  private static readonly POINTS_MAX_WORDS = 50;

  readonly foundWords: string[] = [];

  constructor(readonly level: JumbledDifficultyLevel, readonly layout: JumbledLayout) {
    super();
  }

  getPoints(duration: number): number {
    const values = JumbledDifficultyLevel.VALUES;
    const min = values[0].foundTotalWinRatio;
    const max = values[values.length - 1].foundTotalWinRatio;
    const timePoints = Math.floor(50 * Math.max(0, 1 - duration / JumbledGameBoard.POINTS_MAX_DURATION));
    const winRatioPoints = Math.floor(50 * (this.level.foundTotalWinRatio - min) / (max - min));
    const totalWordsPoints = Math.floor(50 * Math.min(1, this.layout.words.length / JumbledGameBoard.POINTS_MAX_WORDS));
    return Math.max(0, timePoints + winRatioPoints + totalWordsPoints);
  }

  toJSON(): any {
    return this;
  }
}