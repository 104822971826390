import React, {createRef, ReactElement} from "react";
import {colorGray, colorRed, gray} from "../../../shared/colors";
import {Box, Button, Typography} from "@mui/material";
import {StyledBoxColumn, StyledBoxRow} from "../../../shared/StyledComponents";
import {PD_LG, PD_MD} from "../../../shared/dimens";
import {SudokuDifficultyLevel, SudokuGameBoard, SudokuGameConfig, SudokuGameData} from "./types";
import {BaseApp, DIALOG_FLAG_SHOW_CLOSE} from "../../../shared/BaseApp";
import {GameFragment, GameFragmentProps, GameFragmentState} from "../GameFragment";
import {HelpOutlineOutlined, MoreHorizOutlined} from "@mui/icons-material";
import {Action} from "../../../shared/types";
import {GameOverResult, GameOverResultType} from "../types";
import {StringUtil} from "../../../shared/string_util";
import {$KTS} from "../../../shared/keytext";

import {GAME_SUDOKU} from "../../Games";
import {Game} from "../../types";
import {MINUTE_IN_MILLIS} from "../../../shared/date_util";

export type SudokuGameFragmentProps = GameFragmentProps & {}

enum SudokuGameStatus {
  STATUS_IN_PROGRESS,
  STATUS_INCOMPLETE, // Finished, but NOT solved
  STATUS_COMPLETE, // Finished, and solved
}

type SudokuGameFragmentState = GameFragmentState<SudokuGameConfig, SudokuGameData> & {
  focusIndex: number,
  gameStatus: SudokuGameStatus,
}

export class SudokuGameFragment extends GameFragment<SudokuGameFragmentProps, SudokuGameConfig, SudokuGameData, SudokuGameFragmentState> {

  private readonly canvasParentRef = createRef<HTMLDivElement>();
  private readonly canvasRef = createRef<HTMLCanvasElement>();

  private board: SudokuGameBoard;

  protected getGame(): Game {
    return GAME_SUDOKU;
  }

  protected onCreateState(): SudokuGameFragmentState {
    return {
      ...super.onCreateState(),
      focusIndex: -1,
      gameStatus: SudokuGameStatus.STATUS_IN_PROGRESS,
    };
  }

  componentDidUpdate(prevProps: Readonly<SudokuGameFragmentProps>, prevState: Readonly<SudokuGameFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.gameData !== this.state.gameData) {
      this.board = this.state.gameData.gamePlay.jsonData as SudokuGameBoard;
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const gameConfig = this.state.gameConfig;
    if (!gameConfig) {
      return;
    }
    this.setState({
      gameData: await SudokuGameData.create(gameConfig),
    });
  }

  protected containerContentDidRender() {
    const divElement = this.canvasParentRef.current;
    this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    window.onresize = () => {
      this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    };
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  protected renderConfigContent(): React.ReactElement {
    return this.renderConfigContentForGame(GAME_SUDOKU, <>
      {SudokuDifficultyLevel.VALUES.map(level =>
        <Button variant="contained"
                onClick={() => this.setState({gameConfig: new SudokuGameConfig(level)})}>{level.text}</Button>)}
    </>);
  }

  protected renderToolbarTitle(): React.ReactElement {
    return <Typography
      style={{marginLeft: PD_MD,}}>{(this.state.gameData.gamePlay.jsonData as SudokuGameBoard).level.text}</Typography>
  }

  protected renderToolbarButtons() {
    return <>
      <Button onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
        return <StyledBoxColumn style={{padding: PD_LG}}>
          <Typography variant="h5">How to play Sudoku</Typography>
          <Typography>Fill each 3 x 3 set with numbers 1–9.</Typography>
          <ul>
            <li><Typography>Tap a cell in any set, then select a number.</Typography></li>
            <li><Typography>Fill cells until the board is complete. Numbers in sets, rows or columns cannot
              repeat.</Typography></li>
            <li><Typography>Note: Each number can only appear on the board 9 times.</Typography></li>
          </ul>
          <Typography variant="h5">Play modes and tips</Typography>
          <ul>
            <li><Typography>Choose from 4 levels - easy, medium, hard and very hard. To change levels, tap Back in the
              toolbar.</Typography></li>
            <li><Typography>Need a clue? Tap Hint to see the next logical cell to solve.</Typography></li>
            <li><Typography><b style={{color: colorRed}}>Note that using help to check or reveal a cell or board will make the game ineligible for your stats or top charts.</b></Typography></li>
          </ul>
        </StyledBoxColumn>;
      })}>
        <HelpOutlineOutlined/>
      </Button>
      <Button onClick={(event) => BaseApp.CONTEXT.showActions(event.target as HTMLButtonElement, null, [
        new Action("Check cell", () => this.onCheckCell()),
        new Action("Check board", () => this.onCheckBoard()),
        new Action("Reveal cell", () => this.onRevealCell()),
        new Action("Reveal board", () => this.onRevealBoard()),
        new Action("Reset board", () => this.onResetBoard()),
      ])}>
        <MoreHorizOutlined/>
      </Button>
    </>
  }

  protected renderGameContent(): ReactElement | null {
    if (this.board && this.canvasRef.current) {
      this.renderBoard();
    }
    return <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_MD}}>
      <div ref={this.canvasParentRef} style={{width: "100%", maxWidth: 540}}>
        <canvas ref={this.canvasRef} onClick={(event) => {
          if (this.state.gameStatus === SudokuGameStatus.STATUS_COMPLETE) {
            return;
          }
          const rect = this.canvasRef.current.getBoundingClientRect();
          this.onBoardClick(event.clientX - rect.x, event.clientY - rect.y);
        }}/>
      </div>
      <StyledBoxColumn>
        <StyledBoxRow>
          {this.renderButton(1)}
          {this.renderButton(2)}
          {this.renderButton(3)}
          {this.renderButton(4)}
          {this.renderButton(5)}
        </StyledBoxRow>
        <StyledBoxRow>
          {this.renderButton(6)}
          {this.renderButton(7)}
          {this.renderButton(8)}
          {this.renderButton(9)}
          {this.renderButton(0)}
        </StyledBoxRow>
      </StyledBoxColumn>
    </Box>;
  }

  private renderButton(num: number) {
    return <Button onClick={() => this.onInputChanged(num)}><Typography
      variant="h6">{num === 0 ? "✕" : num}</Typography></Button>
  }

  private onInputChanged(num: number) {
    const focusIndex = this.state.focusIndex;
    if (focusIndex >= 0) {
      this.board.board[focusIndex] = num;
      this.board.checked[focusIndex] = 0;
      if (num === 0) {
        //audioPlayer.playClip(MyAudioClips.OOPS);
      }
      this.endInput(true);
    }
    this.forceUpdate();
  }

  private resizeCanvas(width: number, height: number) {
    const current = this.canvasRef.current;
    if (current.width === width && current.height === height) {
      return;
    }
    current.width = width;
    current.height = height;
    this.forceUpdate();
  }

  private onBoardClick(x: number, y: number) {
    if (this.state.focusIndex >= 0) {
      this.endInput(false);
    }
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const tileSize = width / 3;
    const unitSize = tileSize / 3;
    const sx = Math.floor(x / unitSize);
    const sy = Math.floor(y / unitSize);
    if (sx >= 0 && sx < 9 && sy >= 0 && sy < 9) {
      const focusIndex = sy * 9 + sx;
      const state = this.board.board[focusIndex];
      const originalState = this.board.layout.init[focusIndex];
      const fixed = state !== 0 && state === originalState;
      if (!fixed) {
        this.startInput(focusIndex);
      }
    }
  }

  private startInput(focusIndex: number) {
    this.setState({
      focusIndex: focusIndex,
    });
    this.forceUpdate();
  }

  private endInput(didUpdate: boolean) {
    this.setState({
      focusIndex: -1,
    });
    if (didUpdate) {
      // mGameData.writeData(mBoard);
      this.props.gameFragmentListener?.onBoardUpdated();
      this.checkGame();
    }
    this.forceUpdate();
  }

  private onCheckCell() {
    const focusIndex = this.state.focusIndex;
    if (focusIndex < 0) {
      BaseApp.CONTEXT.showToast("No cell selected.");
      return;
    }
    if (this.board.board[focusIndex] <= 0) {
      BaseApp.CONTEXT.showToast("Cell is empty.");
      return;
    }
    this.board.checked[focusIndex] = 1;
    this.board.ineligible = true;
    this.forceUpdate();
  }

  private onCheckBoard() {
    for (let i = 0; i < this.board.board.length; i++) {
      if (this.board.board[i] > 0) {
        this.board.checked[i] = 1;
      }
    }
    this.board.ineligible = true;
    this.forceUpdate();
  }

  private onRevealCell() {
    const focusIndex = this.state.focusIndex;
    if (focusIndex < 0) {
      BaseApp.CONTEXT.showToast("No cell selected.");
      return;
    }
    this.board.board[focusIndex] = this.board.layout.solution[focusIndex];
    this.board.checked[focusIndex] = 0;
    this.board.ineligible = true;
    this.forceUpdate();
  }

  private onRevealBoard() {
    BaseApp.CONTEXT.showAlert("Reveal board", "Are you sure you want to reveal the board? The game will end immediately.", [
      new Action("Reveal and end game", () => {
        for (let i = 0; i < this.board.board.length; i++) {
          this.board.board[i] = this.board.layout.solution[i];
          this.board.checked[i] = 0;
        }
        this.board.ineligible = true;
        this.endInput(true);
      }),
    ]);
  }

  private onResetBoard() {
    BaseApp.CONTEXT.showAlert("Reset board", "Are you sure you want to reset this game? All your progress will be lost.", [
      new Action("Reset", () => {
        for (let i = 0; i < this.board.board.length; i++) {
          this.board.board[i] = this.board.layout.init[i];
          this.board.checked[i] = 0;
        }
        this.endInput(true);
        this.props.gameFragmentListener?.onGameReset();
      }),
    ]);
  }

  private checkGame() {
    const gameStatus = this.checkGameStatus();
    switch (gameStatus) {
      case SudokuGameStatus.STATUS_COMPLETE:
        this.state.gameData.gamePlay.finish();
        //audioPlayer.playClip(MyAudioClips.WIN);
        this.props.gameFragmentListener?.onStatusChanged("Game complete.");
        let resultText: string = "You completed %{level} game in %{duration}.";
        switch (this.state.gameConfig.level) {
          case SudokuDifficultyLevel.EASY:
            resultText = StringUtil.format(resultText, $KTS("level", "an easy"));
            break;
          case SudokuDifficultyLevel.MEDIUM:
            resultText = StringUtil.format(resultText, $KTS("level", "a medium"));
            break;
          case SudokuDifficultyLevel.HARD:
            resultText = StringUtil.format(resultText, $KTS("level", "a hard"));
            break;
          case SudokuDifficultyLevel.VERY_HARD:
            resultText = StringUtil.format(resultText, $KTS("level", "a very hard"));
            break;
        }
        const duration = this.state.gameData.getDuration();
        resultText = StringUtil.format(resultText, $KTS("duration", (duration / MINUTE_IN_MILLIS).toFixed(0) + " minutes"));
        const ineligible = this.board.ineligible;
        this.finishGame(new GameOverResult(GAME_SUDOKU.id, GameOverResultType.WON, this.board.level.name, this.board.getPoints(duration), 0, resultText, ineligible));
        break;
      case SudokuGameStatus.STATUS_INCOMPLETE:
        //audioPlayer.playClip(MyAudioClips.OH);
        this.props.gameFragmentListener?.onStatusChanged("Game incomplete.");
        //((CommonActivity) getContext()).getTipBar().show(R.string.done_incorrect);
        break;
    }
    this.setState({
      gameStatus: gameStatus,
    });
  }

  private checkGameStatus(): SudokuGameStatus {
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        let index = i * 9 + j;
        const n = this.board.board[index];
        if (n === 0) {
          return SudokuGameStatus.STATUS_IN_PROGRESS;
        }
        if (this.board.board[index] !== this.board.layout.solution[index]) {
          return SudokuGameStatus.STATUS_INCOMPLETE;
        }
      }
    }
    return SudokuGameStatus.STATUS_COMPLETE;
  }

  private renderBoard() {
    if (!this.board) {
      return;
    }
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const scale = width / 600;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, width, height);

    const tileSize = width / 3;
    const unitSize = tileSize / 3;
    const innerLineWidth = scale * 2;
    const checkLineWidth = scale * 4;
    ctx.font = "bold " + 32 * scale + "px sans-serif";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        const index = i * 9 + j;
        const state = this.board.board[index];
        const originalState = this.board.layout.init[index];
        const fixed = state > 0 && state === originalState;
        if (fixed) {
          ctx.fillStyle = gray;
          ctx.fillRect(unitSize * j, unitSize * i, unitSize, unitSize);
        } else {
          if (index === this.state.focusIndex) {
            ctx.fillStyle = GAME_SUDOKU.color;
            ctx.fillRect(unitSize * j, unitSize * i, unitSize, unitSize);
          } else {
            ctx.fillStyle = "white";
            ctx.fillRect(unitSize * j, unitSize * i, unitSize, unitSize);
          }
        }
        if (state) {
          ctx.fillStyle = "black";
          ctx.fillText("" + state, unitSize * j + unitSize / 2, unitSize * i + unitSize / 2 + scale * 5);
        }
        if (!fixed && state > 0 && this.board.checked[index] !== 0 && state !== this.board.layout.solution[index]) {
          ctx.strokeStyle = colorRed;
          ctx.lineWidth = checkLineWidth;
          ctx.beginPath();
          ctx.moveTo(unitSize * j, unitSize * i);
          ctx.lineTo(unitSize * j + unitSize, unitSize * i + unitSize);
          ctx.stroke();
        }
      }
    }

    ctx.strokeStyle = colorGray;
    ctx.lineWidth = innerLineWidth;
    for (let i = 1; i < 9; i++) {
      ctx.strokeRect(unitSize * i, 0, unitSize * i, height);
      ctx.strokeRect(0, unitSize * i, width, unitSize * i);
    }

    const outerLineWidth = scale * 4;
    ctx.strokeStyle = "black";
    ctx.lineWidth = outerLineWidth;
    for (let i = 1; i < 3; i++) {
      ctx.strokeRect(tileSize * i, 0, tileSize * i, height);
      ctx.strokeRect(0, tileSize * i, width, tileSize * i);
    }
    ctx.strokeRect(outerLineWidth / 2, outerLineWidth / 2, width - outerLineWidth, height - outerLineWidth);
  }
}