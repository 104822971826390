import {JSONType} from "../../shared/types";

export abstract class GameBoard implements JSONType {

  ineligible: boolean = false;

  abstract getPoints(duration: number): number;

  abstract toJSON(): any;
}

export class GamePlayKey implements JSONType {

  constructor(readonly gameId: string, readonly gamePlayId: string) {
  }

  toJSON(): any {
    return {
      "gameId": this.gameId,
      "gamePlayId": this.gamePlayId,
    };
  }
}

export abstract class GamePlayers implements JSONType {

  abstract toJSON(): any;
}

export class GameSetup implements JSONType {

  constructor(readonly key: GamePlayKey, readonly players?: GamePlayers) {
  }

  toJSON(): any {
  }
}

export class GamePlay implements JSONType {

  private finishOffset: number;

  constructor(readonly setup: GameSetup, readonly created: number, startOffset: number, updateOffset: number, finishOffset: number, readonly jsonData: any) {
    this.finishOffset = finishOffset;
  }

  isFinished(): boolean {
    return this.finishOffset > 0;
  }

  finish() {
    this.finishOffset = Math.max(1, Date.now() - this.created);
  }

  getDuration(): number {
    return this.finishOffset;
  }

  toJSON(): any {
  }
}

export abstract class GameConfig {

  protected constructor(readonly players?: GamePlayers) {
  }
}

export abstract class GameData<V extends JSONType> {

  constructor(readonly gamePlay: GamePlay) {
  }

  getDuration(): number {
    return this.gamePlay.getDuration();
  }
}

export abstract class GameSettings {

}

export abstract class Game<T extends GameConfig, V extends GameData<any>, S extends GameSettings> {

}

export enum GameOverResultType {
  WON,
  LOST,
  OTHER,
}

export class GameOverResult {

  constructor(readonly gameId: string, readonly type: GameOverResultType, readonly level: string, readonly points: number, readonly duration: number, readonly resultText?: string, readonly ineligible?: boolean) {
  }
}