import React from "react";
import {Box, Button, Fab, TextField, Typography} from "@mui/material";
import {DW_SM, PD_MD, PD_SM} from "shared/dimens";
import {DeleteOutlined, PersonAddOutlined, PersonOutlined} from "@mui/icons-material";
import {Action, EmptyConfig, UserDisplayName, UserProfilePhoto} from "shared/types";
import {App} from "app/App";
import {Connections, Contact, Invitations} from "app/shared/connect/types";
import {StyledContainer, StyledListItem} from "shared/StyledComponents";
import {PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";

export type ConnectionsFragmentProps = PageFragmentProps & {}

type ConnectionsFragmentState = PageFragmentState & {
  contacts: Contact[],
}

type CreateInvite = {
  email: string,
}

export class ConnectionsFragment extends PageFragment<ConnectionsFragmentProps, ConnectionsFragmentState> {

  private readonly connections = Connections.getInstance();
  private readonly invitations = Invitations.getInstance();

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: PersonOutlined,
      title: "No connections found",
      text: "Use the invite button to invite people to Playtime.",
    };
  }

  protected async fetchOnMount(): Promise<void> {
    this.setState({
      contacts: await this.connections.getOrLoadListItems(),
    });
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <>
      <StyledContainer style={{flexGrow: 1,}}>
        {this.state.contacts?.map(contact => <StyledListItem
            title={UserDisplayName(contact.user)}
            img={UserProfilePhoto(contact.user)}
            object={contact}
            accessory={this.renderItemAccessory(contact)}/>)
          || this.renderEmpty()}
      </StyledContainer>
      <Fab variant="extended"
           color="secondary"
           style={{
             position: "fixed",
             right: 24,
             bottom: 120,
             padding: 24,
           }}
           onClick={() => {
             this.createInvite = {
               email: "",
             };
             App.CONTEXT.showDialog(null, props => this.renderCreateInviteDialog());
           }}>
        <PersonAddOutlined sx={{mr: 1}}/>
        Invite
      </Fab>
    </>;
  }

  protected renderItemAccessory(contact: Contact): React.ReactElement | null {
    return <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
      {this.renderAccessoryMore([
        new Action("Delete", () => {
          App.CONTEXT.showAlert("Delete connection", "Are you sure want to delete this connection? This action cannot be undone.",
            [new Action("Cancel"), new Action("Delete", () => {
              this.connections.deleteListItem(contact)
                .then((value) => {
                  App.CONTEXT.showToast("Deleted.");
                  this.reload();
                })
                .catch(reason => App.CONTEXT.showToast("Oops, something went wrong. Please try again later."));
            }).makeDestructive()]);
        }, DeleteOutlined),
      ])}
    </Box>;
  }

  private createInvite: CreateInvite | null;

  private renderCreateInviteDialog() {
    return <Box style={{display: "flex", flexDirection: "column", padding: PD_MD, gap: PD_MD, minWidth: Math.min(window.innerWidth - 96, DW_SM)}}>
      <Typography variant="h5">Invite to {App.CONTEXT.getAppConfig().name}</Typography>
      <TextField size="small" autoFocus placeholder="Email" onChange={event => this.createInvite.email = event.target.value}/>
      <Button variant="contained" onClick={() => {
        App.CONTEXT.hideDialog();
        this.invitations.sendInvite(this.createInvite.email)
          .then((value) => App.CONTEXT.showToast("Invitation sent."))
          .catch(reason => {
            console.trace(reason);
            App.CONTEXT.showToast("Oops, something went wrong. Please try again later.")
          });
      }}><Typography>Send invitation</Typography></Button>
    </Box>;
  }
}
