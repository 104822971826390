import {GameBoard, GameConfig, GameData, GamePlay, GamePlayKey, GameSetup} from "../types";
import {v4 as uuid} from "uuid";

export class ChessDifficultyLevel {

  static readonly VALUES = new Array<ChessDifficultyLevel>(3);
  static readonly EASY = ChessDifficultyLevel.VALUES[0] = new ChessDifficultyLevel("easy", "Easy", "Easy desc", 0, "games/chess/layout-4x4.txt");
  static readonly MEDIUM = ChessDifficultyLevel.VALUES[1] = new ChessDifficultyLevel("medium", "Medium", "Medium desc", 0, "games/chess/layout-5x5.txt");
  static readonly HARD = ChessDifficultyLevel.VALUES[2] = new ChessDifficultyLevel("hard", "Hard", "Hard desc", 0, "games/chess/layout-6x6.txt");

  constructor(readonly name: string, readonly text: string, readonly description: string, readonly pointsFactor: number, readonly filename: string) {
  }
}

export class ChessGameConfig extends GameConfig {

  constructor(readonly level: ChessDifficultyLevel) {
    super();
  }
}

export class ChessGameSetup extends GameSetup {

  constructor(key: GamePlayKey) {
    super(key);
  }
}

export class ChessGameData extends GameData<ChessGameBoard> {

  static async create(config: ChessGameConfig): Promise<ChessGameData> {
    const setup = new ChessGameSetup(new GamePlayKey("chess", uuid()));
    const now = Date.now();
    return new ChessGameData(new GamePlay(setup, now, 0, 0, 0, new ChessGameBoard(config.level).toJSON()));
  }
}

export class ChessGameBoard extends GameBoard {

  constructor(readonly level: ChessDifficultyLevel) {
    super();
  }

  getPoints(duration: number): number {
    return 0;
  }

  toJSON(): any {
    return this;
  }
}