import {BaseHistoryFragment} from "./BaseHistoryFragment";
import {Score, Scores, ScoreToString} from "../types";
import {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow} from "../../shared/StyledComponents";
import {Typography} from "@mui/material";
import {DateUtil, MINUTE_IN_MILLIS} from "../../shared/date_util";

export class ScoreHistoryFragment extends BaseHistoryFragment<Score> {

  private readonly scores = Scores.getInstance();

  async getItems(): Promise<Score[]> {
    return this.scores.getOrLoadListItems();
  }

  renderItem(item: Score): ReactElement {
    return <StyledBoxRow style={{flexGrow: 1}}>
      <Typography>{DateUtil.toDatestamp(new Date(item.created))}</Typography>
      <StyledBoxColumn style={{flexGrow: 1}}>
        <Typography variant="h5">{(item.duration / MINUTE_IN_MILLIS).toFixed(0) + "m"}</Typography>
        <Typography>{ScoreToString(item)}</Typography>
      </StyledBoxColumn>
    </StyledBoxRow>;
  }
}