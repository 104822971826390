import {GameBoard, GameConfig, GameData, GamePlay, GamePlayKey, GameSetup} from "../types";
import {v4 as uuid} from "uuid";

export const PAIRS_OBJECTS: string[] = [
  "1F34E",
  "26BD",
  "1F426",
  "1F408",
  "1F415",
  "1F986",
  "1F418",
  "1F41F",
  "1F410",
  "1F40E",
  "1F4A1",
  "1F56F",
  "1F52B",
  "2702",
  "1F4B0",
  "1F4FA",
  "1F3B8",
  "1F3B7",
  "270F",
  "1F4BC",
  "1F5D1",
  "1F369",
  "1F367",
  "1F37C",
  "1F37A",
  "1F37F",
  "1F354",
  "1F344",
  "1F9C0",
  "1F355",
  "1F349",
  "1F347",
  "1F34C",
  "1F34D",
  "1F352",
  "1F382",
  "2615",
  "1F680",
  "1F69C",
  "1F3AD",
  "1F5FD",
  "1F682",
  "1F6B2",
  "1F6F5",
  "1F697",
  "1F6E9",
  "1F31E",
  "2B50",
  "2602",
  "1F525",
  "1F4A7",
  "1F308",
  "1F388",
  "1F381",
  "1F3B2",
  "1F384",
  "1F380",
  "1F413",
  "1F98B",
  "1F41E",
  "1F339",
  "1F341",
  "1F412",
  "1F404",
  "1F416",
  "1F479",
  "1F47E",
  "1F47D",
  "1F47B",
  "1F916",
  "1F4A9",
  "1F9E0",
  "1F463",
  "1F441",
  "1F48B",
  "1F49D",
  "1F4A3",
  "1F4A5",
  "1F453",
  "1F455",
  "1F454",
  "1F9E6",
  "1F45B",
  "1F392",
  "1F45F",
  "1F3A9",
  "1F452",
  "1F451",
  "1F48E",
  "1F3C0",
  "1F0CF",
  "1F94A",
  "1F41D",
  "1F41B",
  "1F983",
  "1F400",
  "1F951",
  "1F965",
  "1F34B",
  "1F95A",
  "1F3FA", // For luck.
];

export class PairsLayout {
  constructor(readonly level: PairsDifficultyLevel, readonly board: number[]) {
  }

  getWinCount(): number {
    return this.board.length / 2;
  }
}

export class PairsDifficultyLevel {

  static readonly VALUES = new Array<PairsDifficultyLevel>(4);
  static readonly EASY = PairsDifficultyLevel.VALUES[0] = new PairsDifficultyLevel("easy", "Easy", "Easy desc", 4);
  static readonly MEDIUM = PairsDifficultyLevel.VALUES[1] = new PairsDifficultyLevel("medium", "Medium", "Medium desc", 6);
  static readonly HARD = PairsDifficultyLevel.VALUES[2] = new PairsDifficultyLevel("hard", "Hard", "Hard desc", 8);
  static readonly VERY_HARD = PairsDifficultyLevel.VALUES[3] = new PairsDifficultyLevel("very_hard", "Very Hard", "Very Hard desc", 10);

  constructor(readonly name: string, readonly text: string, readonly description: string, readonly gridSize: number) {
  }
}

export class PairsGameConfig extends GameConfig {

  constructor(readonly level: PairsDifficultyLevel) {
    super();
  }
}

export class PairsGameSetup extends GameSetup {

  constructor(key: GamePlayKey) {
    super(key);
  }
}

export class PairsGameData extends GameData<PairsGameBoard> {

  static async create(config: PairsGameConfig): Promise<PairsGameData> {
    const loader = new PairsGamesLoader();
    const layout = await loader.getRandomGame(config.level);
    const setup = new PairsGameSetup(new GamePlayKey("pairs", uuid()));
    const now = Date.now();
    return new PairsGameData(new GamePlay(setup, now, 0, 0, 0, new PairsGameBoard(config.level, layout).toJSON()));
  }
}

export class PairsGamesLoader {

  constructor() {
  }

  async getRandomGame(level: PairsDifficultyLevel): Promise<PairsLayout> {
    const gridSize = level.gridSize;
    const board = new Array<number>(gridSize * gridSize);
    const unused: number[] = [];
    for (let i = 0; i < board.length; i++) {
      unused.push(i);
    }
    const objectIndices = new Array<number>(PAIRS_OBJECTS.length);
    for (let i = 0; i < PAIRS_OBJECTS.length; i++) {
      objectIndices[i] = i;
    }
    for (let i = 0; i < board.length; i += 2) {
      const use1 = unused.splice(Math.floor(unused.length * Math.random()), 1)[0];
      const use2 = unused.splice(Math.floor(unused.length * Math.random()), 1)[0];
      board[use1] = board[use2] = objectIndices.splice(Math.floor(objectIndices.length * Math.random()), 1)[0];
    }
    return new PairsLayout(level, board);
  }
}

export class PairsGameBoard extends GameBoard {

  readonly matchedObjectIndices: number[] = [];

  private movesCount: number;

  constructor(readonly level: PairsDifficultyLevel, readonly layout: PairsLayout) {
    super();
    this.movesCount = 0;
  }

  getMovesCount(): number {
    return this.movesCount;
  }

  incrementMovesCount() {
    this.movesCount++;
  }

  getPoints(duration: number): number {
    return Math.max(0, this.level.gridSize * this.level.gridSize * 3 - this.getMovesCount());
  }

  toJSON(): any {
    return this;
  }
}