import {GameBoard, GameConfig, GameData, GamePlay, GamePlayKey, GameSetup} from "../types";
import {v4 as uuid} from "uuid";

export const SPELLBOUND_MIN_WORD_LENGTH = 4;

export class SpellboundLayout {

  chars: string[];

  constructor(readonly level: SpellboundDifficultyLevel, chars: string[], readonly pangrams: string[], readonly words: string[]) {
    this.chars = chars;
  }

  getWinCount(): number {
    return Math.floor(this.level.foundTotalWinRatio * this.words.length);
  }

  shuffleOtherChars() {
    const indexes: number[] = [];
    for (let i = 1; i < this.chars.length; i++) {
      indexes.push(i);
    }
    const chars: string[] = [this.chars[0]];
    while (indexes.length > 0) {
      chars.push(this.chars[indexes.splice(Math.floor(Math.random() * indexes.length), 1)[0]]);
    }
    this.chars = chars;
  }
}

export class SpellboundDifficultyLevel {

  static readonly VALUES = new Array<SpellboundDifficultyLevel>(3);
  static readonly EASY = SpellboundDifficultyLevel.VALUES[0] = new SpellboundDifficultyLevel("easy", "Easy", "Easy desc", 7, "games/spellbound/layout-7.txt", 0.5);
  static readonly MEDIUM = SpellboundDifficultyLevel.VALUES[1] = new SpellboundDifficultyLevel("medium", "Medium", "Medium desc", 8, "games/spellbound/layout-8.txt", 0.5);
  static readonly HARD = SpellboundDifficultyLevel.VALUES[2] = new SpellboundDifficultyLevel("hard", "Hard", "Hard desc", 9, "games/spellbound/layout-9.txt", 0.5);

  constructor(readonly name: string, readonly text: string, readonly description: string, readonly letterCount: number, readonly filename: string, readonly foundTotalWinRatio: number) {
  }
}

export class SpellboundGameConfig extends GameConfig {

  constructor(readonly level: SpellboundDifficultyLevel) {
    super();
  }
}

export class SpellboundGameSetup extends GameSetup {

  constructor(key: GamePlayKey) {
    super(key);
  }
}

export class SpellboundGameData extends GameData<SpellboundGameBoard> {

  static async create(config: SpellboundGameConfig): Promise<SpellboundGameData> {
    const loader = new SpellboundGamesLoader();
    const layout = await loader.getRandomGame(config.level);
    const setup = new SpellboundGameSetup(new GamePlayKey("spellbound", uuid()));
    const now = Date.now();
    return new SpellboundGameData(new GamePlay(setup, now, 0, 0, 0, new SpellboundGameBoard(config.level, layout).toJSON()));
  }
}

export class SpellboundGamesLoader {

  async getRandomGame(level: SpellboundDifficultyLevel): Promise<SpellboundLayout> {
    const lines = (await fetch("/" + level.filename).then(result => result.text())).split("\n");
    const tokens = lines[Math.floor(lines.length * Math.random())].split(",");
    const chars = tokens[0].split("");
    const centerChar = tokens[1];
    const centerCharIndex = chars.findIndex(s => s === centerChar);
    const t = chars[centerCharIndex];
    chars[centerCharIndex] = chars[0];
    chars[0] = t;
    return new SpellboundLayout(level, chars, tokens[2].split(" "), tokens[3].split(" "));
  }
}

export class SpellboundGameBoard extends GameBoard {

  // Max duration to earn time-based points
  private static readonly POINTS_MAX_DURATION = 10 * 60 * 1000; // 10 mins
  // Max words to earn total words-based points
  private static readonly POINTS_MAX_WORDS = 50;

  readonly foundWords: string[] = [];

  constructor(readonly level: SpellboundDifficultyLevel, readonly layout: SpellboundLayout) {
    super();
  }

  getPoints(duration: number): number {
    const values = SpellboundDifficultyLevel.VALUES;
    const min = values[0].foundTotalWinRatio;
    const max = values[values.length - 1].foundTotalWinRatio;
    const timePoints = Math.floor(50 * Math.max(0, 1 - duration / SpellboundGameBoard.POINTS_MAX_DURATION));
    const winRatioPoints = Math.floor(50 * (this.level.foundTotalWinRatio - min) / (max - min));
    const totalWordsPoints = Math.floor(50 * Math.min(1, this.layout.words.length / SpellboundGameBoard.POINTS_MAX_WORDS));
    return Math.max(0, timePoints + winRatioPoints + totalWordsPoints);
  }

  toJSON(): any {
    return this;
  }
}