import React, {ReactElement} from "react";
import {StyledContainer} from "shared/StyledComponents";
import {PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";
import {ScoreItem, ScoreItems} from "../types";
import {Api} from "app/api";
import {GAMES} from "../Games";

export type BaseScoresFragmentProps = PageFragmentProps & {}

export type BaseScoresFragmentState = PageFragmentState & {
  scoreItems: ScoreItems,
  groupByDs: Group<Group<Group<ScoreItem[]>>>,
}

export class Group<S> {

  readonly map = new Map<string, S>();

  get(key: string): S {
    return this.map.get(key);
  }

  getOrCreate(key: string, create: () => S): S {
    let s = this.map.get(key);
    if (!s) {
      s = create();
      this.map.set(key, s);
    }
    return s;
  }

  keys(): string[] {
    return [...this.map.keys()];
  }
}

export abstract class BaseScoresFragment<P extends BaseScoresFragmentProps = BaseScoresFragmentProps, S extends BaseScoresFragmentState = BaseScoresFragmentState> extends PageFragment<P, S> {

  private readonly api = Api.getInstance();

  protected async fetchOnMount(): Promise<void> {
    const scoreItems = await this.api.getScoreItems();
    const groupByDs = new Group<Group<Group<ScoreItem[]>>>();
    for (const scoreItem of scoreItems.list) {
      const ds = scoreItem.score.ds;
      for (const game of GAMES) {
        const gameId = scoreItem.score.gameId;
        if (game.id !== gameId) {
          continue;
        }
        const gameLevel = scoreItem.score.level;
        for (const level of game.levels) {
          if (level.name !== gameLevel) {
            continue;
          }
          const groupByGame = groupByDs.getOrCreate(ds, () => new Group<Group<ScoreItem[]>>());
          const groupByLevel = groupByGame.getOrCreate(game.id, () => new Group<ScoreItem[]>());
          const list = groupByLevel.getOrCreate(level.name, () => []);
          list.push(scoreItem);
        }
      }
    }
    this.setState({
      scoreItems: scoreItems,
      groupByDs
    });
  }

  protected renderContainerContent(): ReactElement {
    return <StyledContainer style={{flexGrow: 1,}}>
      {this.renderScores() || this.renderEmpty()}
    </StyledContainer>
  }

  protected abstract renderScores(): ReactElement | null;
}
