import {FooterTabFragment, FooterTabFragmentProps, FooterTabFragmentState} from "../../shared/FooterTabFragment";
import {Button, Grid, IconButton, Typography} from "@mui/material";
import {StyledBoxColumn, StyledBoxRow, StyledContainer} from "../../shared/StyledComponents";
import React from "react";
import {GameFragmentListener} from "./GameFragment";
import {GameOverResult, GameOverResultType} from "./types";
import {
  BaseApp,
  DIALOG_FLAG_DISABLE_BACKDROP_CLICK,
  DIALOG_FLAG_SHOW_CLOSE,
  DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN
} from "../../shared/BaseApp";
import {PD_LG, PD_MD} from "../../shared/dimens";
import {Game, Score} from "../types";
import {Api} from "../api";
import {GameCard} from "../GameCard";
import {GAMES} from "../Games";
import {MoreHorizOutlined} from "@mui/icons-material";
import {App} from "../App";
import {Action} from "../../shared/types";


export type GamesFragmentProps = FooterTabFragmentProps & {}

export type GamesFragmentState = FooterTabFragmentState & {
  selectedGame?: Game,
}

export class GamesFragment extends FooterTabFragment<GamesFragmentProps, GamesFragmentState> implements GameFragmentListener {

  private readonly api = Api.getInstance();

  getTitle(): string {
    return "Games";
  }

  onBoardUpdated() {
  }

  onGameEnded() {
  }

  onGameOver(result: GameOverResult, ...args) {
    switch (result.type) {
      case GameOverResultType.WON:
        if (!result.ineligible) {
          this.api.setScore(Score.createNew(result.gameId, result.level, result.points, result.duration));
        }
        BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_DISABLE_BACKDROP_CLICK | DIALOG_FLAG_SHOW_CLOSE}, () => {
          return <StyledBoxColumn style={{padding: PD_LG}}>
            <Typography variant="h5">Congratulations!</Typography>
            <Typography>{result.resultText}</Typography>
            {result.ineligible ?
              <Typography>Because you used a hint or check tool, this game will not be counted towards your stats or
                charts.</Typography> : null}
            <Button variant="contained" onClick={() => {
              BaseApp.CONTEXT.hideAllDialogs();
              this.props.path.navigate("/stats/" + result.gameId);
            }}>View my stats</Button>
            <Button variant="outlined" onClick={() => {
              BaseApp.CONTEXT.hideAllDialogs();
              this.props.path.navigate("/charts/" + result.gameId);
            }}>View charts</Button>
          </StyledBoxColumn>;
        });
    }
  }

  onGameReset() {
  }

  onStatusChanged(status: string) {
  }

  renderContent(): React.ReactElement {
    return this.state.selectedGame ?
      this.state.selectedGame.render(this.props.path, this)
      : <StyledContainer size="lg" style={{padding: PD_LG}}>
        <Grid container spacing={2}>
          {GAMES.map(game => <Grid item xs={12} sm={6} lg={4}>
              <GameCard game={game} onClick={() => this.showGame(game)}>
                <StyledBoxColumn style={{background: "white", padding: PD_LG}}>
                  <Typography style={{textAlign: "center", height: "4rem"}}>{game.description}</Typography>
                  {/*<Button variant="contained" style={{flexGrow: 1}} onClick={() => this.showGame(game)}>Play</Button>*/}
                </StyledBoxColumn>
                <IconButton style={{position: "absolute", right: PD_MD, top: PD_MD}} onClick={(event) => {
                  event.stopPropagation();
                  App.CONTEXT.showActions(event.target as HTMLButtonElement, null, [
                    new Action("Stats", () => this.props.path.navigate("/stats/" + game.id)),
                    new Action("Charts", () => this.props.path.navigate("/charts/" + game.id)),
                  ]);
                }}>
                  <MoreHorizOutlined/>
                </IconButton>
              </GameCard>
            </Grid>
          )}
        </Grid>
      </StyledContainer>;
  }

  private showGame(game: Game) {
    BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN,}, () => game.render(this.props.path, this));
  }
}
