import {JsonObject} from "../common/json/json-object";
import {JsonProperty} from "../common/json/json-property";
import {v4 as uuid} from "uuid";
import {ReactElement} from "react";
import {PathProps} from "../index";
import {BaseListItem, BaseListItemsLoader} from "../shared/types";
import {JSON_OBJECT} from "../shared/json/helpers";
import {getMemberAuth} from "../shared/auth";
import {User} from "../shared/entities";
import {DateUtil} from "../shared/date_util";

@JsonObject()
export class Score extends BaseListItem {

  static createNew(gameId: string, level: string, points: number, duration: number) {
    const memberId = getMemberAuth().getMemberId();
    const now = new Date();
    return new Score(uuid(), memberId, now.getTime(), gameId, level, DateUtil.toDatestamp(now), points, duration);
  }

  @JsonObject()
  readonly scoreId;
  @JsonProperty()
  readonly gameId: string;
  @JsonProperty()
  readonly level: string;
  @JsonProperty()
  readonly ds: string;
  @JsonProperty()
  readonly points: number;
  @JsonProperty()
  readonly duration: number;

  constructor(scoreId: string, creator: string, created: number, gameId: string, level: string, ds: string, points: number, duration: number) {
    super(scoreId, creator, created);
    this.scoreId = scoreId;
    this.gameId = gameId;
    this.level = level;
    this.ds = ds;
    this.points = points;
    this.duration = duration;
  }
}

export function ScoreToString(score: Score) {
  return ScoreTimeToString(score.created);
}

export function ScoreTimeToString(time: number) {
  return new Date(time).toLocaleString("en-us", {
    dateStyle: "medium",
    timeStyle: "short"
  })
}

export class Scores extends BaseListItemsLoader<Score> {

  private static instance;

  static getInstance(): Scores {
    if (!this.instance) {
      this.instance = new Scores();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "scores";
  }

  protected deserializeItem(value: any): Score {
    return JSON_OBJECT.deserializeObject(value, Score);
  }

  protected serializeItem(item: Score): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Score, item2: Score): number {
    // Reverse chronological
    return item2.created - item1.created;
  }
}

export type ScoreItem = {
  user: User,
  score: Score,
}

export class ScoreItems {

  readonly list: ScoreItem[] = [];

  add(user: User, score: Score) {
    this.list.push({user: user, score: score});
  }
}

export type GameLevel = {
  name: string,
  text: string,
}

export type Game = {
  id: string,
  name: string,
  description: string,
  icon: string,
  color: string,
  levels: GameLevel[],
  render: (pathProps: PathProps, ...args) => ReactElement,
}
