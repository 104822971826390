import {GameLevel, Score, ScoreItem} from "../types";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledListItem} from "../../shared/StyledComponents";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import {EmojiEventsOutlined, ExpandMoreOutlined} from "@mui/icons-material";
import {BaseScoresFragment, BaseScoresFragmentProps, BaseScoresFragmentState} from "../shared/BaseScoresFragment";
import {DateUtil} from "../../shared/date_util";
import {PD_LG, PD_MD, PD_SM, PD_XLG} from "../../shared/dimens";
import {EmptyConfig, UserDisplayName, UserProfilePhoto} from "../../shared/types";
import {DAYS_HISTORY, GAMES} from "../Games";

export type GameChartsFragmentProps = BaseScoresFragmentProps & {
  gameId: string,
}

type GameChartsFragmentState = BaseScoresFragmentState & {}

export class GameChartsFragment extends BaseScoresFragment<GameChartsFragmentProps, GameChartsFragmentState> {

  getTitle(): string {
    return "";
  }

  protected renderScores(): React.ReactElement | null {
    const rendered: ReactElement[] = [];
    for (let i = 0; i < DAYS_HISTORY; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      rendered.push(this.renderScoresForDate(date));
    }
    return <StyledContainer>
      {rendered}
    </StyledContainer>;
  }

  private renderScoresForDate(date: Date): React.ReactElement | null {
    const game = GAMES.find(game => game.id === this.props.gameId);
    const ds = DateUtil.toDatestamp(date);
    const groupByGame = this.state.groupByDs.get(ds);
    const groupByLevel = groupByGame?.get(this.props.gameId);
    const chartsMap = new Map<GameLevel, ScoreItem[]>();
    game.levels.forEach(level => {
      const scoreItems = groupByLevel?.get(level.name);
      if (!(scoreItems?.length > 0)) {
        return;
      }
      chartsMap.set(level, scoreItems);
    });
    if (chartsMap.size <= 0) {
      return null;
    }
    return <StyledBoxColumn style={{marginBottom: PD_XLG, gap: 0}}>
      <Typography variant="h5" style={{marginBottom: PD_LG}}>{ds}</Typography>
      {[...chartsMap.entries()].map(entry => {
        const level = entry[0];
        const charts = entry[1];
        if (!(charts?.length > 0)) {
          return null;
        }
        return this.renderCharts(level, charts);
      })}
    </StyledBoxColumn>;
  }

  renderCharts(level: GameLevel, scoreItems: ScoreItem[]): ReactElement {
    return <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreOutlined/>}>
        <Typography variant="h6">{level.text}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <StyledBoxColumn>
          {this.renderScoreItems(scoreItems)}
        </StyledBoxColumn>
      </AccordionDetails>
    </Accordion>;
  }

  private renderScoreItems(scoreItemsList: ScoreItem[]) {
    const listItems = scoreItemsList?.map((item, index) =>
      <StyledBoxRow style={{alignItems: "center", gap: PD_MD, paddingLeft: PD_MD}}>
        <Typography variant="h6">{index + 1}.</Typography>
        <StyledListItem
          style={{flexGrow: 1}}
          title={UserDisplayName(item.user)}
          img={UserProfilePhoto(item.user)}
          object={item}
          accessory={this.renderItemAccessory(item.score)}/>
      </StyledBoxRow>
    );
    if (!listItems || listItems.length === 0) {
      return null;
    }
    return <StyledBoxColumn>
      {listItems}
    </StyledBoxColumn>;
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      title: "And the winner is...",
      text: "You can add people to compete with from the People tab. You can then see who's leading here.",
      iconType: EmojiEventsOutlined,
    };
  }

  protected renderItemAccessory(score: Score): React.ReactElement | null {
    return <Box style={{display: "flex", alignItems: "center", gap: PD_SM, padding: PD_MD}}>
      <Typography>{score.points} pts</Typography>
    </Box>;
  }
}