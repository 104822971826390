import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {PD_SM} from "shared/dimens";
import {MailOutlined} from "@mui/icons-material";
import {remove} from "@firebase/database";
import {md5} from "shared/md5";
import {App} from "app/App";
import {dbRef, dbRef_removeVal, dbRef_setVal} from "shared/database";
import {Contact, Invitations, Invite} from "app/shared/connect/types";
import {EmptyConfig, UserDisplayName, UserProfilePhoto} from "../../shared/types";
import {Members, User} from "../../shared/entities";
import {getMemberAuth} from "../../shared/auth";
import {JSON_OBJECT} from "../../common/json/helpers";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {StyledContainer, StyledListItem} from "../../shared/StyledComponents";

export type InvitationsFragmentProps = PageFragmentProps & {}

type InvitationsFragmentState = PageFragmentState & {
  invites: InviteItem[],
}

type InviteItem = {
  invite: Invite,
  fromUser: User,
}

export class InvitationsFragment extends PageFragment<InvitationsFragmentProps, InvitationsFragmentState> {

  private readonly invitations = Invitations.getInstance();

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: MailOutlined,
      title: "No invitations found",
      text: "When someone sends you an invitation to Playtime, it will show up here.",
    };
  }

  protected async fetchOnMount(): Promise<void> {
    await this.invitations.loadInvitations();
    this.setState({
      invites: await Promise.all(this.invitations.getReceived().map(invite => {
        async function getInviteItem(invite: Invite) {
          const user = (await Members.getInstance().getOrLoadMember(invite.creator))?.user;
          return {
            invite: invite,
            fromUser: user,
          } as InviteItem;
        }

        return getInviteItem(invite);
      }))
    })
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <>
      <StyledContainer style={{flexGrow: 1,}}>
        {this.state.invites?.map(invite => <StyledListItem
            title={UserDisplayName(invite.fromUser)}
            img={UserProfilePhoto(invite.fromUser)}
            object={invite}
            accessory={this.renderItemAccessory(invite)}/>)
          || this.renderEmpty()}
      </StyledContainer>
    </>;
  }

  protected renderItemAccessory(item: InviteItem): React.ReactElement | null {
    return <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
      <Button
        onMouseDown={event => event.stopPropagation()}
        onClick={() => {
          const memberAuth = getMemberAuth();

          async function accept() {
            // Add sender as connection.
            const contact = Contact.createFromInvite(item.invite, memberAuth.member.memberId);
            await dbRef_setVal("connections/" + contact.creator + "/" + contact.id, JSON_OBJECT.serializeObject(contact));
            // Remove invite I received.
            await dbRef_removeVal("invitations/received/" + md5(memberAuth.auth.currentUser.email) + "/" + item.invite.id);
            // Send invite back to sender from me.
            const invite = Invite.createNew(memberAuth.member.memberId, memberAuth.auth.currentUser.email, item.invite.fromEmail);
            await dbRef_setVal("invitations/received/" + md5(invite.toEmail) + "/" + invite.id, JSON_OBJECT.serializeObject(invite));
          }

          accept()
            .then(() => {
              App.CONTEXT.showToast("Accepted.");
              this.reload();
            })
            .catch(reason => {
              console.log(reason);
              App.CONTEXT.showToast("Oops, something went wrong. Please try again later.")
            });
        }}
      ><Typography>Accept</Typography></Button>
      <Button
        onMouseDown={event => event.stopPropagation()}
        onClick={() => {
          const memberAuth = getMemberAuth();
          const newConnectionRef = dbRef("invitations/received/" + md5(memberAuth.auth.currentUser.email) + "/" + item.invite.id);
          remove(newConnectionRef)
            .then((value) => {
              App.CONTEXT.showToast("Rejected.");
              this.reload();
            })
            .catch(reason => App.CONTEXT.showToast("Oops, something went wrong. Please try again later."));
        }}
      ><Typography>Reject</Typography></Button>
    </Box>;
  }
}
