import React, {ReactElement} from "react";
import {Box, Tab, Tabs, useMediaQuery, useTheme} from "@mui/material";
import {PD_MD, SZ_MD} from "./dimens";
import {Outlet} from "react-router-dom";
import {TabsContainer, TabsContainerProps, TabsContainerState} from "./TabsContainer";
import {PluginHostFragment} from "./PluginHostFragment";
import {BaseApp} from "./BaseApp";
import {gray} from "./colors";
import {StyledSpan} from "./StyledComponents";

export type HeaderTabsContainerProps<TAB_TYPE> = TabsContainerProps<TAB_TYPE> & {
  accessory?: ReactElement,
}

export type HeaderTabsContainerState<TAB_TYPE> = TabsContainerState<TAB_TYPE> & {}

function HeaderBar<TAB_TYPE>(props: { toolbar?: ReactElement, tabPath: string } & TabsContainerProps<TAB_TYPE>) {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('sm'));
  return <Box style={{
    display: "flex",
    flexShrink: 0,
    height: SZ_MD,
    alignItems: "center",
    paddingLeft: PD_MD,
    paddingRight: PD_MD,
    gap: PD_MD,
    position: "relative",
  }}>
    {!Boolean(props.toolbar) || large ? <StyledSpan/> : null}
    <Tabs
      TabIndicatorProps={{
        style: {
          height: 0,
        }
      }}
      scrollButtons={true}
      centered
      value={props.tabPath}
      onChange={(event, value) => props.path.navigate(value)}>
      {TabsContainer.expandGroups(props.tabs).items.filter(tab => !tab.hidden).map(tab => {
        const IconType = tab.iconType;
        return <Tab iconPosition="start" icon={IconType ? <IconType style={{width: 32, height: 32}}/> : null} label={tab.text}
                    value={tab.path} sx={{
          "&.Mui-selected": {
            backgroundColor: gray,
          },
          "&.MuiTab-root": {
            minHeight: 48,
          },
        }}/>;
      })}
    </Tabs>
    <StyledSpan/>
    {Boolean(props.toolbar)
      ? <Box style={{display: "flex", position: "absolute", right: 0}}>
        {props.toolbar}
      </Box>
      : null}
  </Box>

}

export abstract class HeaderTabsContainer<TAB_TYPE, P extends HeaderTabsContainerProps<TAB_TYPE>, S extends HeaderTabsContainerState<TAB_TYPE>> extends TabsContainer<TAB_TYPE, P, S> {

  render() {
    const toolbar = this.renderToolbar();
    return <Box
      style={{display: "flex", flexGrow: 1, flexDirection: "column", position: "relative", overflowX: "hidden"}}>
      <HeaderBar path={this.props.path} tabs={this.props.tabs} tabPath={this.state.tabPath} toolbar={toolbar}/>
      <Outlet/>
      <PluginHostFragment hostId={BaseApp.CONTEXT.getAppConfig().name + "-" + this.props.tabs.containerId}/>
    </Box>
  }

  protected renderToolbar(): ReactElement | null {
    return null;
  }
}
