import {FooterTabFragment} from "../../shared/FooterTabFragment";
import React from "react";
import {Box} from "@mui/material";
import {TabInfo, Tabs} from "../../shared/TabsContainer";
import {GAMES, GameTabType} from "../Games";
import {
  HeaderTabsContainer,
  HeaderTabsContainerProps,
  HeaderTabsContainerState
} from "../../shared/HeaderTabsContainer";
import {PathComponent, PathProps} from "../../index";
import {GameChartsFragment} from "./GameChartsFragment";

function createTabInfo(type: GameTabType): TabInfo<GameTabType> {
  const game = GAMES.find(game => game.id === type);
  return {
    type: type,
    path: type,
    text: game.name,
    render: (path: PathProps) => <GameChartsFragment gameId={game.id} path={path}/>,
  }
}

const TABS: Tabs<GameTabType> = {
  items: Object.keys(GameTabType).map(key => createTabInfo(GameTabType[key])),
  containerId: "charts",
};

type ChartsTabsContainerProps = HeaderTabsContainerProps<GameTabType> & {}

type ChartsTabsContainerState = HeaderTabsContainerState<GameTabType> & {}

class ChartsTabsContainer extends HeaderTabsContainer<GameTabType, ChartsTabsContainerProps, ChartsTabsContainerState> {
}

export class ChartsFragment extends FooterTabFragment {

  static nestedPaths(): PathComponent[] {
    return ChartsTabsContainer.nestedPathsFromTabs(TABS);
  }

  getTitle(): string {
    return "Charts";
  }

  renderContent(): React.ReactElement | null {
    return <Box style={{display: "flex", flexGrow: 1}}>
      <ChartsTabsContainer path={this.props.path} tabs={TABS}/>
    </Box>;
  }
}
