import {AppConfig, BaseApp, BaseAppProps, BaseAppState} from "shared/BaseApp";
import React, {ReactElement} from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS} from "../shared/colors";
import {Main} from "./Main";
import {PathComponent} from "../index";
import {FIREBASE_CONFIG} from "../consts";
import {BORDER_RADIUS, SZ_SM} from "../shared/dimens";
import {Auth, indexedDBLocalPersistence, initializeAuth} from "@firebase/auth";
import {FirebaseApp} from "firebase/app";
import {Capacitor} from "@capacitor/core";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export const COLOR_PRIMARY = "#42210b";

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  components: {
    ...DEFAULT_THEME_OPTIONS.components,
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          fontSize: "75%",
          borderRadius: BORDER_RADIUS,
        },
      }
    },
  },
  typography: {
    h1: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h2: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h3: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h4: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h5: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h6: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    // fontFamily: 'THICCCBOI, sans-serif',
    fontSize: 15,
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
      contrastText: "#fff",
    },
    secondary: {
      main: "#000",
      contrastText: "#fff",
    },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

export class App extends BaseApp<AppProps, AppState> {

  protected getAuth(app: FirebaseApp): Auth {
    return Capacitor.isNativePlatform()
      ? initializeAuth(app, {
        persistence: indexedDBLocalPersistence
      })
      : super.getAuth(app);
  }

  onCreateAppConfig(): AppConfig {
    return {
      name: "Puzzles",
      icon: "/icon.png",
      logo: "/logo192.png",
      stamp: "/stamp.png",
      stampHeight: SZ_SM,
      stampText: "Puzzles",
      safeAreaInsets: {
        bottom: Capacitor.getPlatform() === "ios" ? 12 : 0,
      },
      defaultUserImage: "/images/default_user.png",
      theme: THEME,
      firebaseConfig: {options: FIREBASE_CONFIG},
    };
  }

  static nestedPaths(): PathComponent[] {
    return Main.nestedPaths();
  }

  protected renderMain(): ReactElement {
    return <>
      <Main path={this.props.path}/>
    </>;
  }
}
