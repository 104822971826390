import {JsonObject} from "common/json/json-object";
import {JsonProperty} from "common/json/json-property";
import {BaseListItem, BaseListItemsLoader} from "shared/types";
import {JSON_OBJECT} from "shared/json/helpers";
import {md5} from "shared/md5";
import {get, push, set} from "@firebase/database";
import {dbRef, dbRef_setVal} from "shared/database";
import {getMemberAuth} from "shared/auth";
import {Members, User} from "shared/entities";

@JsonObject()
export class Invite extends BaseListItem {

  @JsonProperty()
  fromEmail: string;
  @JsonProperty()
  toEmail: string;

  static createNew(creator: string, fromEmail: string, toEmail: string) {
    return new Invite(md5(creator), creator, Date.now(), fromEmail, toEmail);
  }

  constructor(id: string, creator: string, created: number, fromEmail: string, toEmail: string) {
    super(id, creator, created);
    this.fromEmail = fromEmail;
    this.toEmail = toEmail;
  }
}

@JsonObject()
export class Contact extends BaseListItem {

  @JsonProperty()
  contactId: string; // member id of contact

  user: User;

  static createFromInvite(invite: Invite, creator: string) {
    return new Contact(md5(creator, invite.creator), creator, Date.now(), invite.creator);
  }

  constructor(id: string, creator: string, created: number, contactId: string) {
    super(id, creator, created);
    this.contactId = contactId;
  }
}

export class Connections extends BaseListItemsLoader<Contact> {

  private static instance;

  static getInstance(): Connections {
    if (!this.instance) {
      this.instance = new Connections();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "connections";
  }

  protected deserializeItem(value: any): Contact {
    return JSON_OBJECT.deserializeObject(value, Contact);
  }

  protected serializeItem(item: Contact): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected async onAfterItemDeserialized(item: Contact): Promise<Contact> {
    item.user = (await Members.getInstance().getOrLoadMember(item.contactId))?.user;
    return item;
  }

  protected sortOrder(item1: Contact, item2: Contact): number {
    return item1.created - item2.created;
  }
}

export class Invitations {

  private static instance;

  static getInstance(): Invitations {
    if (!this.instance) {
      this.instance = new Invitations();
    }
    return this.instance;
  }

  private readonly memberAuth = getMemberAuth();

  private received: Invite[];
  private sent: Invite[];

  async loadInvitations(): Promise<void> {
    if (this.received === undefined || this.sent === undefined) {
      this.received = await this.load("invitations/received/");
      this.sent = await this.load("invitations/sent/");
    }
  }

  private async load(path: string): Promise<Invite[]> {
    const invitationsRef = dbRef(path + md5(this.memberAuth.auth.currentUser.email));
    const result = await get(invitationsRef);
    const invitations: Invite[] = [];
    if (result.exists()) {
      const val = result.val();
      for (const key in val) {
        const invite = JSON_OBJECT.deserializeObject(val[key], Invite);
        invitations.push(invite);
      }
      return invitations;
    }
    return [];
  }

  getReceived(): Invite[] {
    return this.received;
  }

  getSent(): Invite[] {
    return this.sent;
  }

  async sendInvite(email: string): Promise<void> {
    const invite = Invite.createNew(this.memberAuth.member.memberId, this.memberAuth.auth.currentUser.email, email);
    await dbRef_setVal("invitations/sent/" + md5(invite.fromEmail) + "/" + invite.id, JSON_OBJECT.serializeObject(invite));
    await dbRef_setVal("invitations/received/" + md5(invite.toEmail) + "/" + invite.id, JSON_OBJECT.serializeObject(invite));
  }
}
