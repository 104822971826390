import React, {createRef, ReactElement} from "react";
import {Box, Button, List, ListItem, Typography} from "@mui/material";
import {StyledBoxColumn, StyledBoxRow, StyledContainer} from "../../../shared/StyledComponents";
import {PD_LG, PD_MD, PD_XLG} from "../../../shared/dimens";
import {
  SPELLBOUND_MIN_WORD_LENGTH,
  SpellboundDifficultyLevel,
  SpellboundGameBoard,
  SpellboundGameConfig,
  SpellboundGameData
} from "./types";
import {BaseApp, DIALOG_FLAG_SHOW_CLOSE} from "../../../shared/BaseApp";
import {GameFragment, GameFragmentProps, GameFragmentState} from "../GameFragment";
import {HelpOutlineOutlined, MoreHorizOutlined} from "@mui/icons-material";
import {GameOverResult, GameOverResultType} from "../types";
import {StringUtil} from "../../../shared/string_util";
import {$KTS} from "../../../shared/keytext";

import {GAME_SPELLBOUND} from "../../Games";
import {Game} from "../../types";
import {black, colorHighlightAlt, gray} from "../../../shared/colors";
import {Action} from "../../../shared/types";
import {MINUTE_IN_MILLIS} from "../../../shared/date_util";

export type SpellboundGameFragmentProps = GameFragmentProps & {}

enum SpellboundGameStatus {
  STATUS_IN_PROGRESS,
  STATUS_INCOMPLETE, // Finished, but NOT solved
  STATUS_COMPLETE, // Finished, and solved
}

type SpellboundGameFragmentState = GameFragmentState<SpellboundGameConfig, SpellboundGameData> & {
  focusIndex: number,
  inputWord: string,
  gameStatus: SpellboundGameStatus,
}

class Rect {
  x: number;
  y: number;
  w: number;
  h: number;

  set(x: number, y: number, w: number, h: number) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
  }

  contains(x: number, y: number) {
    return this.x <= x && (this.x + this.w) >= x && this.y <= y && (this.y + this.h) >= y;
  }
}

export class SpellboundGameFragment extends GameFragment<SpellboundGameFragmentProps, SpellboundGameConfig, SpellboundGameData, SpellboundGameFragmentState> {

  private readonly canvasParentRef = createRef<HTMLDivElement>();
  private readonly canvasRef = createRef<HTMLCanvasElement>();

  private board: SpellboundGameBoard;

  private charRects: Rect[];

  protected getGame(): Game {
    return GAME_SPELLBOUND;
  }

  protected onCreateState(): SpellboundGameFragmentState {
    return {
      ...super.onCreateState(),
      focusIndex: -1,
      inputWord: "",
      gameStatus: SpellboundGameStatus.STATUS_IN_PROGRESS,
    };
  }

  componentDidUpdate(prevProps: Readonly<SpellboundGameFragmentProps>, prevState: Readonly<SpellboundGameFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.gameData !== this.state.gameData) {
      this.board = this.state.gameData.gamePlay.jsonData as SpellboundGameBoard;
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const gameConfig = this.state.gameConfig;
    if (!gameConfig) {
      return;
    }
    this.setState({
      gameData: await SpellboundGameData.create(gameConfig),
    });
  }

  protected containerContentDidRender() {
    const divElement = this.canvasParentRef.current;
    this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    window.onresize = () => {
      this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    };
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  protected renderConfigContent(): React.ReactElement {
    return this.renderConfigContentForGame(GAME_SPELLBOUND, <>
      {SpellboundDifficultyLevel.VALUES.map(level =>
        <Button variant="contained"
                onClick={() => this.setState({gameConfig: new SpellboundGameConfig(level)})}>{level.text}</Button>)}
    </>);
  }

  protected renderToolbarTitle(): React.ReactElement {
    return <Typography
      style={{marginLeft: PD_MD,}}>{(this.state.gameData.gamePlay.jsonData as SpellboundGameBoard).level.text}</Typography>
  }

  protected renderToolbarButtons() {
    return <>
      <Button onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
        return <StyledBoxColumn style={{padding: PD_LG}}>
          <Typography variant="h5">How to play Spellbound</Typography>
          <Typography>Fill each 3 x 3 set with numbers 1–9.</Typography>
          <List>
            <ListItem>Tap a cell in any set, then select a number.</ListItem>
            <ListItem>Fill cells until the board is complete. Numbers in sets, rows or columns cannot
              repeat.</ListItem>
            <ListItem>Note: Each number can only appear on the board 9 times.</ListItem>
          </List>
          <Typography variant="h5">Play modes and tips</Typography>
          <List>
            <ListItem>Normal mode: Add 1 number to a cell.</ListItem>
            <ListItem>Candidate mode: Add several numbers to a cell (for multiple options).</ListItem>
            <ListItem>Need a clue? Tap Hint to see the next logical cell to solve.</ListItem>
            <ListItem>Choose from 4 levels - easy, medium, hard and very hard. To change levels, tap Back in the
              toolbar.</ListItem>
            <ListItem>New puzzles for each level are released daily: Sunday–Thursday at 10 p.m.; Friday–Saturday at 6
              p.m.</ListItem>
          </List>
        </StyledBoxColumn>;
      })}>,
        <HelpOutlineOutlined/>
      </Button>
      <Button onClick={(event) => BaseApp.CONTEXT.showActions(event.target as HTMLButtonElement, null, [
        new Action("Reveal pangrams", () => {
          BaseApp.CONTEXT.showToast(this.board.layout.pangrams.join(", "));
        }),
      ])}>
        <MoreHorizOutlined/>
      </Button>
    </>
  }

  protected renderGameContent(): ReactElement | null {
    if (this.board && this.canvasRef.current) {
      this.renderBoard();
    }
    return <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_MD}}>
      <div ref={this.canvasParentRef} style={{width: "100%", maxWidth: 540}}>
        <canvas ref={this.canvasRef} onClick={(event) => {
          if (this.state.gameStatus === SpellboundGameStatus.STATUS_COMPLETE) {
            return;
          }
          const rect = this.canvasRef.current.getBoundingClientRect();
          this.onBoardClick(event.clientX - rect.x, event.clientY - rect.y);
        }}/>
      </div>
      <StyledContainer style={{gap: PD_LG, alignItems: "center"}}>
        <StyledBoxRow>
          <Button style={{paddingLeft: PD_XLG, paddingRight: PD_XLG}} onClick={() => this.deleteInput()}>Delete</Button>
          <Button style={{paddingLeft: PD_XLG, paddingRight: PD_XLG}} onClick={() => this.shuffleInput()}>Shuffle</Button>
          <Button style={{paddingLeft: PD_XLG, paddingRight: PD_XLG}} onClick={() => this.endInput()}>Check</Button>
        </StyledBoxRow>
        <Typography>{this.board?.foundWords?.join(", ")}</Typography>
      </StyledContainer>
    </Box>;
  }

  private resizeCanvas(width: number, height: number) {
    const current = this.canvasRef.current;
    if (current.width === width && current.height === height) {
      return;
    }
    current.width = width;
    current.height = height;
    this.forceUpdate();
  }

  private onBoardClick(x: number, y: number) {
    if (!this.charRects) {
      return;
    }
    this.charRects.forEach((rect, index) => {
      if (rect.contains(x, y)) {
        this.setState({
          focusIndex: index,
          inputWord: this.state.inputWord + this.board.layout.chars[index],
        });
      }
    });
  }

  private clearInput() {
    this.setState({
      focusIndex: -1,
      inputWord: "",
    });
  }

  private deleteInput() {
    if (this.state.inputWord.length > 0) {
      this.setState({
        inputWord: this.state.inputWord.substring(0, this.state.inputWord.length - 1),
      });
    }
  }

  private shuffleInput() {
    this.board.layout.shuffleOtherChars();
    this.forceUpdate();
  }

  private endInput() {
    if (this.checkWord(this.state.inputWord)) {
      if (this.board.layout.pangrams.includes(this.state.inputWord)) {
        BaseApp.CONTEXT.showToast("You found a pangram!");
      }
      // mGameData.writeData(mBoard);
      this.props.gameFragmentListener?.onBoardUpdated();
      this.checkGame();
    }
    this.clearInput();
  }

  private checkWord(word: string): boolean {
    if (!word || word.length < SPELLBOUND_MIN_WORD_LENGTH) {
      BaseApp.CONTEXT.showToast("Word is too short.");
      return false;
    }
    if (!word.includes(this.board.layout.chars[0])) {
      BaseApp.CONTEXT.showToast("Must contain center letter.");
      return false;
    }
    if (this.board.layout.words.findIndex(w => w === word) < 0) {
      BaseApp.CONTEXT.showToast("Word not found.");
      return false;
    }
    if (this.board.foundWords.findIndex(w => w === word) >= 0) {
      BaseApp.CONTEXT.showToast("Word already found.");
      return false;
    }
    this.board.foundWords.push(word);
    return true;
  }

  private checkGame() {
    const gameStatus = this.checkGameStatus();
    switch (gameStatus) {
      case SpellboundGameStatus.STATUS_COMPLETE:
        //audioPlayer.playClip(MyAudioClips.WIN);
        this.props.gameFragmentListener?.onStatusChanged("Game complete.");
        let resultText: string = "You completed %{level} game in %{duration}.";
        switch (this.state.gameConfig.level) {
          case SpellboundDifficultyLevel.EASY:
            resultText = StringUtil.format(resultText, $KTS("level", "an easy"));
            break;
          case SpellboundDifficultyLevel.MEDIUM:
            resultText = StringUtil.format(resultText, $KTS("level", "a medium"));
            break;
          case SpellboundDifficultyLevel.HARD:
            resultText = StringUtil.format(resultText, $KTS("level", "a hard"));
            break;
        }
        const duration = this.state.gameData.getDuration();
        resultText = StringUtil.format(resultText, $KTS("duration", (duration / MINUTE_IN_MILLIS).toFixed(0) + " minutes"));
        const ineligible = this.board.ineligible;
        this.finishGame(new GameOverResult(GAME_SPELLBOUND.id, GameOverResultType.WON, this.board.level.name, this.board.getPoints(duration), 0, resultText, ineligible));
        break;
      case SpellboundGameStatus.STATUS_INCOMPLETE:
        //audioPlayer.playClip(MyAudioClips.OH);
        this.props.gameFragmentListener?.onStatusChanged("Game incomplete.");
        //((CommonActivity) getContext()).getTipBar().show(R.string.done_incorrect);
        break;
    }
    this.setState({
      gameStatus: gameStatus,
    });
  }

  private checkGameStatus(): SpellboundGameStatus {
    if (this.board.foundWords.length < this.board.layout.getWinCount()) {
      return SpellboundGameStatus.STATUS_IN_PROGRESS;
    }
    return SpellboundGameStatus.STATUS_COMPLETE;
  }

  private drawHighlight(ctx: CanvasRenderingContext2D, r: Rect, size: number) {
    ctx.fillStyle = colorHighlightAlt;
    ctx.beginPath();
    ctx.roundRect(r.x, r.y, r.w, r.h, size / 2);
    ctx.fill();
  }

  private renderBoard() {
    if (!this.board) {
      return;
    }
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const size = Math.min(width, height) / 5;
    const scale = width / 600;
    const ctx = canvas.getContext("2d");
    const count = this.board.level.letterCount;
    ctx.clearRect(0, 0, width, height);
    if (!this.charRects) {
      this.charRects = [];
      for (let i = 0; i < count; i++) {
        this.charRects.push(new Rect());
      }
    }
    const cx = width / 2;
    const cy = height / 2;
    ctx.font = "bold " + Math.floor(32 * scale) + "px sans-serif";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fillStyle = black;
    ctx.fillText(this.state.inputWord, cx, 32);

    const size2 = size / 2;
    this.charRects[0].set(cx - size2, cy - size2, size, size);
    let r = this.charRects[0];
    ctx.fillStyle = GAME_SPELLBOUND.color;
    ctx.beginPath();
    ctx.roundRect(r.x, r.y, r.w, r.h, size / 2);
    ctx.fill();
    if (this.state.focusIndex === 0) {
      this.drawHighlight(ctx, r, size);
    }

    ctx.font = "bold " + Math.floor(56 * scale) + "px sans-serif";
    ctx.fillStyle = black;
    ctx.fillText(this.board.layout.chars[0], cx, cy);

    const count1 = count - 1;
    for (let i = 1; i < count; i++) {
      const angle = i * 2 * Math.PI / count1;
      const ocx = cx + size * Math.sin(angle) * 1.33;
      const ocy = cx + size * Math.cos(angle) * 1.33;
      this.charRects[i].set(ocx - size2, ocy - size2, size, size);
      r = this.charRects[i];
      ctx.fillStyle = gray;
      ctx.beginPath();
      ctx.roundRect(r.x, r.y, r.w, r.h, size / 2);
      ctx.fill();
      if (this.state.focusIndex === i) {
        this.drawHighlight(ctx, r, size);
      }
      ctx.fillStyle = black;
      ctx.fillText(this.board.layout.chars[i], ocx, ocy);
    }
  }
}