import React, {createRef, ReactElement} from "react";
import {Box, Button, List, ListItem, Typography} from "@mui/material";
import {StyledBoxColumn, StyledBoxRow, StyledContainer} from "../../../shared/StyledComponents";
import {PD_LG, PD_MD, PD_XXLG} from "../../../shared/dimens";
import {
  JUMBLED_MIN_WORD_LENGTH,
  JumbledDifficultyLevel,
  JumbledGameBoard,
  JumbledGameConfig,
  JumbledGameData
} from "./types";
import {BaseApp, DIALOG_FLAG_SHOW_CLOSE} from "../../../shared/BaseApp";
import {GameFragment, GameFragmentProps, GameFragmentState} from "../GameFragment";
import {HelpOutlineOutlined, MoreHorizOutlined} from "@mui/icons-material";
import {GameOverResult, GameOverResultType} from "../types";
import {StringUtil} from "../../../shared/string_util";
import {$KTS} from "../../../shared/keytext";

import {GAME_JUMBLED} from "../../Games";
import {Game} from "../../types";
import {MINUTE_IN_MILLIS} from "../../../shared/date_util";
import {Simulate} from "react-dom/test-utils";
import invalid = Simulate.invalid;

export type JumbledGameFragmentProps = GameFragmentProps & {}

enum JumbledGameStatus {
  STATUS_IN_PROGRESS,
  STATUS_INCOMPLETE, // Finished, but NOT solved
  STATUS_COMPLETE, // Finished, and solved
}

type JumbledGameFragmentState = GameFragmentState<JumbledGameConfig, JumbledGameData> & {
  focusIndices: number[],
  gameStatus: JumbledGameStatus,
}

export class JumbledGameFragment extends GameFragment<JumbledGameFragmentProps, JumbledGameConfig, JumbledGameData, JumbledGameFragmentState> {

  private readonly canvasParentRef = createRef<HTMLDivElement>();
  private readonly canvasRef = createRef<HTMLCanvasElement>();

  private board: JumbledGameBoard;

  protected getGame(): Game {
    return GAME_JUMBLED;
  }

  protected onCreateState(): JumbledGameFragmentState {
    return {
      ...super.onCreateState(),
      focusIndices: [],
      gameStatus: JumbledGameStatus.STATUS_IN_PROGRESS,
    };
  }

  componentDidUpdate(prevProps: Readonly<JumbledGameFragmentProps>, prevState: Readonly<JumbledGameFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.gameData !== this.state.gameData) {
      this.board = this.state.gameData.gamePlay.jsonData as JumbledGameBoard;
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const gameConfig = this.state.gameConfig;
    if (!gameConfig) {
      return;
    }
    this.setState({
      gameData: await JumbledGameData.create(gameConfig),
    });
  }

  protected containerContentDidRender() {
    const divElement = this.canvasParentRef.current;
    this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    window.onresize = () => {
      this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    };
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  protected renderConfigContent(): React.ReactElement {
    return this.renderConfigContentForGame(GAME_JUMBLED, <>
      {JumbledDifficultyLevel.VALUES.map(level =>
        <Button variant="contained"
                onClick={() => this.setState({gameConfig: new JumbledGameConfig(level)})}>{level.text}</Button>)}
    </>);
  }

  protected renderToolbarTitle(): React.ReactElement {
    return <Typography style={{marginLeft: PD_MD,}}>{(this.state.gameData.gamePlay.jsonData as JumbledGameBoard).level.text}</Typography>
  }

  protected renderToolbarButtons() {
    return <>
      <Button onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
        return <StyledBoxColumn style={{padding: PD_LG}}>
          <Typography variant="h5">How to play Jumbled</Typography>
          <Typography>Race against time to find as many 3 or more letter words as you can.</Typography>
          <List>
            <ListItem>Words must be formed using adjacent (horizontal, vertical or diagonal) letters.</ListItem>
            <ListItem>A letter cannot be re-used in a word.</ListItem>
          </List>
          <Typography variant="h5">Play modes and tips</Typography>
          <List>
            <ListItem>Choose from 4 levels - easy, medium, hard and very hard.</ListItem>
            <ListItem>Tap on a letter again to check & enter the word.</ListItem>
          </List>
        </StyledBoxColumn>;
      })}>,
        <HelpOutlineOutlined/>
      </Button>
      <Button onClick={(event) => BaseApp.CONTEXT.showActions(event.target as HTMLButtonElement, null, [])}>
        <MoreHorizOutlined/>
      </Button>
    </>
  }

  protected renderGameContent(): ReactElement | null {
    if (this.board && this.canvasRef.current) {
      this.renderBoard();
    }
    const wordsFound = this.board?.foundWords?.length || 0;
    const totalWords = this.board?.layout.getWinCount();
    return <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_MD}}>
      <div ref={this.canvasParentRef} style={{width: "100%", maxWidth: 540}}>
        <canvas ref={this.canvasRef} onClick={(event) => {
          if (this.state.gameStatus === JumbledGameStatus.STATUS_COMPLETE) {
            return;
          }
          const rect = this.canvasRef.current.getBoundingClientRect();
          this.onBoardClick(event.clientX - rect.x, event.clientY - rect.y);
        }}/>
      </div>
      <StyledContainer style={{gap: PD_LG, alignItems: "center"}}>
        <StyledBoxRow>
          <Button style={{paddingLeft: PD_XXLG, paddingRight: PD_XXLG}} onClick={() => this.clearInput()}>Clear</Button>
          <Button style={{paddingLeft: PD_XXLG, paddingRight: PD_XXLG}} onClick={() => this.endInput()}>Check</Button>
        </StyledBoxRow>
        <StyledBoxRow>
          <Typography style={{flexGrow: 1, fontWeight: "bold"}}>Words found: {wordsFound} of {totalWords}</Typography>
        </StyledBoxRow>
        <Typography>{this.board?.foundWords?.join(", ")}</Typography>
      </StyledContainer>
    </Box>;
  }

  private resizeCanvas(width: number, height: number) {
    const current = this.canvasRef.current;
    if (current.width === width && current.height === height) {
      return;
    }
    current.width = width;
    current.height = height;
    this.forceUpdate();
  }

  private onBoardClick(x: number, y: number) {
    const gridSize = this.board.level.gridSize;
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const unitSize = width / gridSize;
    const sx = Math.floor(x / unitSize);
    const sy = Math.floor(y / unitSize);
    const index = sy * gridSize + sx;
    const n = this.state.focusIndices.indexOf(index);
    if (n < 0) {
      let invalid: boolean = false;
      if (this.state.focusIndices.length > 0) {
        const lastFocusIndex = this.state.focusIndices[this.state.focusIndices.length - 1];
        const lastSx = Math.floor(lastFocusIndex % gridSize);
        const lastSy = Math.floor(lastFocusIndex / gridSize);
        invalid = Math.abs(sx - lastSx) > 1 || Math.abs(sy - lastSy) > 1;
      }
      if (!invalid) {
        this.setState({
          focusIndices: [...this.state.focusIndices, index],
        });
      }
    } else if (n === this.state.focusIndices.length - 1) {
      this.endInput();
    }
  }

  private clearInput() {
    this.setState({
      focusIndices: [],
    });
  }

  private endInput() {
    const word = this.state.focusIndices.map(n => this.board.layout.board[n]).join("");
    if (this.checkWord(word)) {
      // mGameData.writeData(mBoard);
      this.props.gameFragmentListener?.onBoardUpdated();
      this.checkGame();
    }
    this.clearInput();
  }

  private checkWord(word: string): boolean {
    if (!word || word.length < JUMBLED_MIN_WORD_LENGTH) {
      BaseApp.CONTEXT.showToast("Word is too short.");
      return false;
    }
    if (this.board.layout.words.findIndex(w => w === word) < 0) {
      BaseApp.CONTEXT.showToast("Word not found.");
      return false;
    }
    if (this.board.foundWords.findIndex(w => w === word) >= 0) {
      BaseApp.CONTEXT.showToast("Word already found.");
      return false;
    }
    this.board.foundWords.push(word);
    return true;
  }

  private checkGame() {
    const gameStatus = this.checkGameStatus();
    switch (gameStatus) {
      case JumbledGameStatus.STATUS_COMPLETE:
        //audioPlayer.playClip(MyAudioClips.WIN);
        this.props.gameFragmentListener?.onStatusChanged("Game complete.");
        let resultText: string = "You completed %{level} game in %{duration}.";
        switch (this.state.gameConfig.level) {
          case JumbledDifficultyLevel.EASY:
            resultText = StringUtil.format(resultText, $KTS("level", "an easy"));
            break;
          case JumbledDifficultyLevel.MEDIUM:
            resultText = StringUtil.format(resultText, $KTS("level", "a medium"));
            break;
          case JumbledDifficultyLevel.HARD:
            resultText = StringUtil.format(resultText, $KTS("level", "a hard"));
            break;
        }
        const duration = this.state.gameData.getDuration();
        resultText = StringUtil.format(resultText, $KTS("duration", (duration / MINUTE_IN_MILLIS).toFixed(0) + " minutes"));
        const ineligible = this.board.ineligible;
        this.finishGame(new GameOverResult(GAME_JUMBLED.id, GameOverResultType.WON, this.board.level.name, this.board.getPoints(duration), 0, resultText, ineligible));
        break;
      case JumbledGameStatus.STATUS_INCOMPLETE:
        //audioPlayer.playClip(MyAudioClips.OH);
        this.props.gameFragmentListener?.onStatusChanged("Game incomplete.");
        //((CommonActivity) getContext()).getTipBar().show(R.string.done_incorrect);
        break;
    }
    this.setState({
      gameStatus: gameStatus,
    });
  }

  private checkGameStatus(): JumbledGameStatus {
    if (this.board.foundWords.length < this.board.layout.getWinCount()) {
      return JumbledGameStatus.STATUS_IN_PROGRESS;
    }
    return JumbledGameStatus.STATUS_COMPLETE;
  }

  private renderBoard() {
    if (!this.board) {
      return;
    }
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const scale = width / 600;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, width, height);
    const gridSize = this.board.level.gridSize;

    const unitSize = width / gridSize;
    const lineWidth = scale * 16;

    for (let i = 0; i < this.state.focusIndices.length - 1; i++) {
      const n1 = this.state.focusIndices[i];
      const n2 = this.state.focusIndices[i + 1];
      ctx.strokeStyle = GAME_JUMBLED.color;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(unitSize * Math.floor(n1 % gridSize) + unitSize / 2, unitSize * Math.floor(n1 / gridSize) + unitSize / 2);
      ctx.lineTo(unitSize * Math.floor(n2 % gridSize) + unitSize / 2, unitSize * Math.floor(n2 / gridSize) + unitSize / 2);
      ctx.stroke();
    }

    ctx.font = "bold " + Math.floor(48 * scale) + "px sans-serif";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    const focusSize = unitSize * 0.75;
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        const index = i * gridSize + j;
        if (this.state.focusIndices.findIndex((value) => value === index) >= 0) {
          ctx.fillStyle = GAME_JUMBLED.color;
          ctx.beginPath();
          ctx.arc(unitSize * j + unitSize / 2, unitSize * i + unitSize / 2, focusSize / 2, 0, 2 * Math.PI, false);
          ctx.fill();
        }
        ctx.fillStyle = "black";
        ctx.fillText("" + this.board.layout.board[index], unitSize * j + unitSize / 2, unitSize * i + unitSize / 2 + scale * 5);
      }
    }

    const outerLineWidth = scale * 4;
    ctx.strokeStyle = "black";
    ctx.lineWidth = outerLineWidth;
    ctx.strokeRect(outerLineWidth / 2, outerLineWidth / 2, width - outerLineWidth, height - outerLineWidth);
  }
}