import {Game} from "./types";
import {colorGreen, colorOrange, colorPastelBlue, colorPastelRed, colorPink, colorYellow} from "../shared/colors";
import {PlaceholderFragment} from "../shared/PlaceholderFragment";
import React from "react";
import {JumbledGameFragment} from "./games/jumbled/JumbledGameFragment";
import {GameFragmentListener} from "./games/GameFragment";
import {ChessGameFragment} from "./games/chess/ChessGameFragment";
import {PairsGameFragment} from "./games/pairs/PairsGameFragment";
import {SudokuGameFragment} from "./games/sudoku/SudokuGameFragment";
import {JumbledDifficultyLevel} from "./games/jumbled/types";
import {PairsDifficultyLevel} from "./games/pairs/types";
import {SudokuDifficultyLevel} from "./games/sudoku/types";
import {SpellboundGameFragment} from "./games/spellbound/SpellboundGameFragment";

export const DAYS_HISTORY: number = 7;

export enum GameTabType {
  SUDOKU = "sudoku",
  PAIRS = "pairs",
  SPELLBOUND = "spellbound",
  JUMBLED = "jumbled",
}

export const GAME_JUMBLED: Game = {
  id: GameTabType.JUMBLED,
  name: "Jumbled",
  description: "Find words using letters on the board.",
  icon: "/games/jumbled/icon.png",
  color: colorOrange,
  levels: JumbledDifficultyLevel.VALUES.map(level => {
    return {name: level.name, text: level.text};
  }),
  render: (path, ...args) => <JumbledGameFragment path={path} gameFragmentListener={args[0] as GameFragmentListener}/>,
};
export const GAME_PAIRS: Game = {
  id: GameTabType.PAIRS,
  name: "Pairs",
  description: "Match the pairs, improve your memory.",
  icon: "/games/pairs/icon.png",
  color: colorPastelRed,
  levels: PairsDifficultyLevel.VALUES.map(level => {
    return {name: level.name, text: level.text};
  }),
  render: (path, ...args) => <PairsGameFragment path={path} gameFragmentListener={args[0] as GameFragmentListener}/>,
};
export const GAME_SUDOKU: Game = {
  id: GameTabType.SUDOKU,
  name: "Sudoku",
  description: "An engaging numbers puzzle, even for numberphobes.",
  icon: "/games/sudoku/icon.png",
  color: colorPastelBlue,
  levels: SudokuDifficultyLevel.VALUES.map(level => {
    return {name: level.name, text: level.text};
  }),
  render: (path, ...args) => <SudokuGameFragment path={path} gameFragmentListener={args[0] as GameFragmentListener}/>,
};
export const GAME_WORDSEARCH: Game = {
  id: "wordsearch",
  name: "Wordsearch",
  description: "Search for words in a letter grid.",
  icon: "/games/sudoku/icon.png",
  color: colorPink,
  levels: [],
  render: path => <PlaceholderFragment/>,
};
export const GAME_SPELLBOUND: Game = {
  id: GameTabType.SPELLBOUND,
  name: "Spellbound",
  description: "How many words can you make?",
  icon: "/games/spellbound/icon.png",
  color: colorYellow,
  levels: [],
  render: (path, ...args) => <SpellboundGameFragment path={path}
                                                     gameFragmentListener={args[0] as GameFragmentListener}/>,
};
export const GAME_CHESS: Game = {
  id: "chess",
  name: "Chess",
  description: "Play the classic strategy game.",
  icon: "/games/chess/icon.png",
  color: colorGreen,
  levels: [],
  render: (path, ...args) => <ChessGameFragment path={path} gameFragmentListener={args[0] as GameFragmentListener}/>,
};

export const GAMES: Game[] = [
  GAME_SUDOKU,
  GAME_PAIRS,
  // GAME_CHESS,
  GAME_SPELLBOUND,
  GAME_JUMBLED,
  // GAME_WORDSEARCH,
];

export function getGameById(gameId: string): Game {
  return GAMES.find(game => game.id === gameId);
}