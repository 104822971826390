import {GameBoard, GameConfig, GameData, GamePlay, GamePlayKey, GameSetup} from "../types";
import {v4 as uuid} from "uuid";

export class SudokuLayout {
  constructor(readonly init: number[], readonly solution: number[]) {
  }
}

export class SudokuDifficultyLevel {

  static readonly VALUES = new Array<SudokuDifficultyLevel>(4);
  static readonly EASY = SudokuDifficultyLevel.VALUES[0] = new SudokuDifficultyLevel("easy", "Easy", "Easy desc", 0, "games/sudoku/easy.txt");
  static readonly MEDIUM = SudokuDifficultyLevel.VALUES[1] = new SudokuDifficultyLevel("medium", "Medium", "Medium desc", .33, "games/sudoku/medium.txt");
  static readonly HARD = SudokuDifficultyLevel.VALUES[2] = new SudokuDifficultyLevel("hard", "Hard", "Hard desc", .67, "games/sudoku/hard.txt");
  static readonly VERY_HARD = SudokuDifficultyLevel.VALUES[3] = new SudokuDifficultyLevel("very_hard", "Very Hard", "Very Hard desc", 1, "games/sudoku/very_hard.txt");

  constructor(readonly name: string, readonly text: string, readonly description: string, readonly pointsFactor: number, readonly filename: string) {
  }
}

export class SudokuGameConfig extends GameConfig {

  constructor(readonly level: SudokuDifficultyLevel) {
    super();
  }
}

export class SudokuGameSetup extends GameSetup {

  constructor(key: GamePlayKey) {
    super(key);
  }
}

export class SudokuGameData extends GameData<SudokuGameBoard> {

  static async create(config: SudokuGameConfig): Promise<SudokuGameData> {
    const loader = new SudokuGamesLoader();
    const layout = await loader.getRandomGame(config.level);
    const setup = new SudokuGameSetup(new GamePlayKey("sudoku", uuid()));
    const now = Date.now();
    return new SudokuGameData(new GamePlay(setup, now, 0, 0, 0, new SudokuGameBoard(config.level, layout).toJSON()));
  }
}

export class SudokuGamesLoader {

  private readonly gamesByDifficultyMap = new Map<SudokuDifficultyLevel, SudokuLayout[]>();

  constructor() {
  }

  async load(level: SudokuDifficultyLevel): Promise<SudokuLayout[]> {
    let games: SudokuLayout[] = this.gamesByDifficultyMap.get(level);
    if (games == null) {
      games = [];
      const lines = (await fetch("/" + level.filename).then(result => result.text())).split("\n");
      for (const line of lines) {
        const separatorAt = line.indexOf(',');
        const boardStr = line.substring(0, separatorAt);
        const init = new Array<number>(9 * 9);
        for (let i = 0; i < init.length; i++) {
          init[i] = boardStr.charCodeAt(i) - '0'.charCodeAt(0);
        }
        const solutionStr = line.substring(separatorAt + 1);
        const solution = new Array<number>(9 * 9);
        for (let i = 0; i < solution.length; i++) {
          solution[i] = solutionStr.charCodeAt(i) - '0'.charCodeAt(0);
        }
        const game = new SudokuLayout(init, solution);
        games.push(game);
      }
      this.gamesByDifficultyMap.set(level, games);
    }
    return games;
  }

  async getRandomGame(difficultyLevel: SudokuDifficultyLevel): Promise<SudokuLayout> {
    const games = await this.load(difficultyLevel);
    return games[Math.floor(games.length * Math.random())];
  }
}

export class SudokuGameBoard extends GameBoard {

  // Max duration to earn time-based points
  private static readonly POINTS_MAX_DURATION = 3 * 60 * 60 * 1000; // 3 hours

  readonly board = new Array<number>();
  readonly checked = new Array<number>();

  constructor(readonly level: SudokuDifficultyLevel, readonly layout: SudokuLayout) {
    super();
    this.board.push(...this.layout.init);
    this.checked.fill(0, 0, 9 * 9);
  }

  getPoints(duration: number): number {
    const values = SudokuDifficultyLevel.VALUES;
    const min = values[0].pointsFactor;
    const max = values[values.length - 1].pointsFactor;
    const timePoints = Math.floor(50 * Math.max(0, 1 - duration / SudokuGameBoard.POINTS_MAX_DURATION));
    const pointsFactorPoints = Math.floor(50 * (this.level.pointsFactor - min) / (max - min));
    return Math.max(0, timePoints + pointsFactorPoints);
  }

  toJSON(): any {
    return this;
  }
}