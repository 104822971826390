import React, {ReactElement} from "react";
import {Box, Button, List, ListItem, Typography} from "@mui/material";
import {StyledBoxColumn} from "../../../shared/StyledComponents";
import {PD_LG, PD_MD} from "../../../shared/dimens";
import {ChessDifficultyLevel, ChessGameBoard, ChessGameConfig, ChessGameData} from "./types";
import {BaseApp, DIALOG_FLAG_SHOW_CLOSE} from "../../../shared/BaseApp";
import {GameFragment, GameFragmentProps, GameFragmentState} from "../GameFragment";
import {HelpOutlineOutlined, MoreHorizOutlined} from "@mui/icons-material";
import {Chessboard} from "react-chessboard";
import {GAME_CHESS} from "../../Games";
import {Game} from "app/types";

export type ChessGameFragmentProps = GameFragmentProps & {}

enum ChessGameStatus {
  STATUS_IN_PROGRESS,
  STATUS_INCOMPLETE, // Finished, but NOT solved
  STATUS_COMPLETE, // Finished, and solved
}

type ChessGameFragmentState = GameFragmentState<ChessGameConfig, ChessGameData> & {
  focusIndex: number,
  gameStatus: ChessGameStatus,
}

export class ChessGameFragment extends GameFragment<ChessGameFragmentProps, ChessGameConfig, ChessGameData, ChessGameFragmentState> {

  private board: ChessGameBoard;

  private boardSize: number;

  protected getGame(): Game {
    return GAME_CHESS;
  }

  protected onCreateState(): ChessGameFragmentState {
    return {
      ...super.onCreateState(),
      focusIndex: -1,
      gameStatus: ChessGameStatus.STATUS_IN_PROGRESS,
    };
  }

  componentDidUpdate(prevProps: Readonly<ChessGameFragmentProps>, prevState: Readonly<ChessGameFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.gameData !== this.state.gameData) {
      this.board = this.state.gameData.gamePlay.jsonData as ChessGameBoard;
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const gameConfig = this.state.gameConfig;
    if (!gameConfig) {
      return;
    }
    this.setState({
      gameData: await ChessGameData.create(gameConfig),
    });
  }

  protected containerContentDidRender() {
    window.onresize = () => {
      this.forceUpdate();
    };
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  protected renderConfigContent(): React.ReactElement {
    return this.renderConfigContentForGame(GAME_CHESS, <>
      {ChessDifficultyLevel.VALUES.map(level =>
        <Button variant="contained"
                onClick={() => this.setState({gameConfig: new ChessGameConfig(level)})}>{level.text}</Button>)}
    </>);
  }

  protected renderToolbarTitle(): React.ReactElement {
    return <Typography>{(this.state.gameData.gamePlay.jsonData as ChessGameBoard).level.text}</Typography>
  }

  protected renderToolbarButtons() {
    return <>
      <Button onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
        return <StyledBoxColumn style={{padding: PD_LG}}>
          <Typography variant="h5">How to play Chess</Typography>
          <Typography>Fill each 3 x 3 set with numbers 1–9.</Typography>
          <List>
            <ListItem>Tap a cell in any set, then select a number.</ListItem>
            <ListItem>Fill cells until the board is complete. Numbers in sets, rows or columns cannot
              repeat.</ListItem>
            <ListItem>Note: Each number can only appear on the board 9 times.</ListItem>
          </List>
          <Typography variant="h5">Play modes and tips</Typography>
          <List>
            <ListItem>Need a clue? Tap Hint to see the next logical cell to solve.</ListItem>
            <ListItem>Choose from 4 levels - easy, medium, hard and very hard. To change levels, tap Back in the
              toolbar.</ListItem>
            <ListItem>New puzzles for each level are released daily: Sunday–Thursday at 10 p.m.; Friday–Saturday at 6
              p.m.</ListItem>
          </List>
        </StyledBoxColumn>;
      })}>,
        <HelpOutlineOutlined/>
      </Button>
      <Button onClick={(event) => BaseApp.CONTEXT.showActions(event.target as HTMLButtonElement, null, [
        // new Action("Check cell", () => this.onCheckCell()),
        // new Action("Check board", () => this.onCheckBoard()),
        // new Action("Reveal cell", () => this.onRevealCell()),
        // new Action("Reveal board", () => this.onRevealBoard()),
        // new Action("Reset board", () => this.onResetBoard()),
      ])}>
        <MoreHorizOutlined/>
      </Button>
    </>
  }

  protected renderGameContent(): ReactElement | null {
    const boardSize = Math.min(window.innerWidth, 540);
    return <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_MD}}>
      <Box style={{width: boardSize, aspectRatio: 1}}>
        <Chessboard boardWidth={boardSize}/>
      </Box>
    </Box>;
  }
}