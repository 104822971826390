import React, {createRef, ReactElement} from "react";
import {colorGray, mediumGray} from "../../../shared/colors";
import {Box, Button, List, ListItem, Typography} from "@mui/material";
import {StyledBoxColumn} from "../../../shared/StyledComponents";
import {PD_LG, PD_MD} from "../../../shared/dimens";
import {PAIRS_OBJECTS, PairsDifficultyLevel, PairsGameBoard, PairsGameConfig, PairsGameData} from "./types";
import {BaseApp, DIALOG_FLAG_SHOW_CLOSE} from "../../../shared/BaseApp";
import {GameFragment, GameFragmentProps, GameFragmentState} from "../GameFragment";
import {HelpOutlineOutlined, MoreHorizOutlined} from "@mui/icons-material";
import {GameOverResult, GameOverResultType} from "../types";
import {StringUtil} from "../../../shared/string_util";
import {$KTS} from "../../../shared/keytext";

import {GAME_PAIRS} from "../../Games";
import {Game} from "../../types";
import {MINUTE_IN_MILLIS} from "../../../shared/date_util";

export type PairsGameFragmentProps = GameFragmentProps & {}

enum PairsGameStatus {
  STATUS_IN_PROGRESS,
  STATUS_INCOMPLETE, // Finished, but NOT solved
  STATUS_COMPLETE, // Finished, and solved
}

type PairsGameFragmentState = GameFragmentState<PairsGameConfig, PairsGameData> & {
  focusIndex1: number,
  focusIndex2: number,
  gameStatus: PairsGameStatus,
}

export class PairsGameFragment extends GameFragment<PairsGameFragmentProps, PairsGameConfig, PairsGameData, PairsGameFragmentState> {

  private readonly canvasParentRef = createRef<HTMLDivElement>();
  private readonly canvasRef = createRef<HTMLCanvasElement>();
  private readonly imageCache = new Map<string, HTMLImageElement>();

  private board: PairsGameBoard;

  protected getGame(): Game {
    return GAME_PAIRS;
  }

  protected onCreateState(): PairsGameFragmentState {
    return {
      ...super.onCreateState(),
      focusIndex1: -1,
      focusIndex2: -1,
      gameStatus: PairsGameStatus.STATUS_IN_PROGRESS,
    };
  }

  componentDidUpdate(prevProps: Readonly<PairsGameFragmentProps>, prevState: Readonly<PairsGameFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.gameData !== this.state.gameData) {
      this.board = this.state.gameData.gamePlay.jsonData as PairsGameBoard;
    }
    if (prevState.focusIndex2 !== this.state.focusIndex2 && this.state.focusIndex1 !== -1 && this.state.focusIndex2 !== -1) {
      let n: number;
      if ((n = this.board.layout.board[this.state.focusIndex1]) === this.board.layout.board[this.state.focusIndex2]) {
        this.board.matchedObjectIndices.push(n);
        this.clearInput();
      } else {
        setTimeout(() => this.clearInput(), 1000);
      }
      this.board.incrementMovesCount();
      // mGameData.writeData(mBoard);
      this.props.gameFragmentListener?.onBoardUpdated();
      this.checkGame();
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const gameConfig = this.state.gameConfig;
    if (!gameConfig) {
      return;
    }
    const gameData = await PairsGameData.create(gameConfig);
    this.setState({
      gameData: gameData,
    });
    // for (const obj of PAIRS_OBJECTS) {
    //   this.imageCache.set(obj, await new Promise<HTMLImageElement>(resolve => {
    //     const img = new Image();
    //     img.addEventListener("load", () => {
    //       resolve(img);
    //     });
    //     img.src = "/games/pairs/emoji/emoji_u" + obj.toLowerCase() + ".png";
    //   }));
    // }
    const board = (gameData.gamePlay.jsonData as PairsGameBoard).layout.board;
    for (let i = 0; i < board.length; i++) {
      const obj = PAIRS_OBJECTS[board[i]];
      if (!this.imageCache.get(obj)) {
        this.imageCache.set(obj, await new Promise<HTMLImageElement>(resolve => {
          const img = new Image();
          img.addEventListener("load", () => {
            resolve(img);
          });
          img.src = "/games/pairs/emoji/emoji_u" + obj.toLowerCase() + ".png";
        }));
      }
    }
  }

  protected containerContentDidRender() {
    const divElement = this.canvasParentRef.current;
    this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    window.onresize = () => {
      this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    };
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  protected renderConfigContent(): React.ReactElement {
    return this.renderConfigContentForGame(GAME_PAIRS, <>
      {PairsDifficultyLevel.VALUES.map(level =>
        <Button variant="contained"
                onClick={() => this.setState({gameConfig: new PairsGameConfig(level)})}>{level.text}</Button>)}
    </>);
  }

  protected renderToolbarTitle(): React.ReactElement {
    return <Typography
      style={{marginLeft: PD_MD,}}>{(this.state.gameData.gamePlay.jsonData as PairsGameBoard).level.text}</Typography>
  }

  protected renderToolbarButtons() {
    return <>
      <Button onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
        return <StyledBoxColumn style={{padding: PD_LG}}>
          <Typography variant="h5">How to play Pairs</Typography>
          <Typography>Fill each 3 x 3 set with numbers 1–9.</Typography>
          <List>
            <ListItem>Tap a cell in any set, then select a number.</ListItem>
            <ListItem>Fill cells until the board is complete. Numbers in sets, rows or columns cannot
              repeat.</ListItem>
            <ListItem>Note: Each number can only appear on the board 9 times.</ListItem>
          </List>
          <Typography variant="h5">Play modes and tips</Typography>
          <List>
            <ListItem>Normal mode: Add 1 number to a cell.</ListItem>
            <ListItem>Candidate mode: Add several numbers to a cell (for multiple options).</ListItem>
            <ListItem>Need a clue? Tap Hint to see the next logical cell to solve.</ListItem>
            <ListItem>Choose from 4 levels - easy, medium, hard and very hard. To change levels, tap Back in the
              toolbar.</ListItem>
            <ListItem>New puzzles for each level are released daily: Sunday–Thursday at 10 p.m.; Friday–Saturday at 6
              p.m.</ListItem>
          </List>
        </StyledBoxColumn>;
      })}>,
        <HelpOutlineOutlined/>
      </Button>
      <Button onClick={(event) => BaseApp.CONTEXT.showActions(event.target as HTMLButtonElement, null, [])}>
        <MoreHorizOutlined/>
      </Button>
    </>
  }

  protected renderGameContent(): ReactElement | null {
    if (this.board && this.canvasRef.current) {
      this.renderBoard();
    }
    return <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_MD}}>
      <div ref={this.canvasParentRef} style={{width: "100%", maxWidth: 540}}>
        <canvas ref={this.canvasRef} onClick={(event) => {
          if (this.state.gameStatus === PairsGameStatus.STATUS_COMPLETE) {
            return;
          }
          const rect = this.canvasRef.current.getBoundingClientRect();
          this.onBoardClick(event.clientX - rect.x, event.clientY - rect.y);
        }}/>
      </div>
    </Box>;
  }

  private resizeCanvas(width: number, height: number) {
    const current = this.canvasRef.current;
    if (current.width === width && current.height === height) {
      return;
    }
    current.width = width;
    current.height = height;
    this.forceUpdate();
  }

  private onBoardClick(x: number, y: number) {
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const gridSize = this.board.level.gridSize;
    const unitSize = width / gridSize;
    const sx = Math.floor(x / unitSize);
    const sy = Math.floor(y / unitSize);
    const focusIndex = sy * gridSize + sx;
    if (this.board.matchedObjectIndices.indexOf(this.board.layout.board[focusIndex]) < 0) {
      if (this.state.focusIndex1 === -1) {
        this.startInput(focusIndex);
      } else if (this.state.focusIndex2 === -1 && focusIndex !== this.state.focusIndex1) {
        this.endInput(focusIndex);
      }
    }
  }

  private clearInput() {
    this.setState({
      focusIndex1: -1,
      focusIndex2: -1,
    });
  }

  private startInput(focusIndex: number) {
    this.setState({
      focusIndex1: focusIndex,
    });
  }

  private endInput(focusIndex: number) {
    this.setState({
      focusIndex2: focusIndex,
    });
  }

  private onCheckBoard() {
    this.forceUpdate();
  }

  private checkGame() {
    const gameStatus = this.checkGameStatus();
    switch (gameStatus) {
      case PairsGameStatus.STATUS_COMPLETE:
        //audioPlayer.playClip(MyAudioClips.WIN);
        this.props.gameFragmentListener?.onStatusChanged("Game complete.");
        let resultText: string = "You completed %{level} game in %{duration}.";
        switch (this.state.gameConfig.level) {
          case PairsDifficultyLevel.EASY:
            resultText = StringUtil.format(resultText, $KTS("level", "an easy"));
            break;
          case PairsDifficultyLevel.MEDIUM:
            resultText = StringUtil.format(resultText, $KTS("level", "a medium"));
            break;
          case PairsDifficultyLevel.HARD:
            resultText = StringUtil.format(resultText, $KTS("level", "a hard"));
            break;
          case PairsDifficultyLevel.VERY_HARD:
            resultText = StringUtil.format(resultText, $KTS("level", "a very hard"));
            break;
        }
        const duration = this.state.gameData.getDuration();
        resultText = StringUtil.format(resultText, $KTS("duration", (duration / MINUTE_IN_MILLIS).toFixed(0) + " minutes"));
        const ineligible = this.board.ineligible;
        this.finishGame(new GameOverResult(GAME_PAIRS.id, GameOverResultType.WON, this.board.level.name, this.board.getPoints(duration), 0, resultText, ineligible));
        break;
      case PairsGameStatus.STATUS_INCOMPLETE:
        //audioPlayer.playClip(MyAudioClips.OH);
        this.props.gameFragmentListener?.onStatusChanged("Game incomplete.");
        //((CommonActivity) getContext()).getTipBar().show(R.string.done_incorrect);
        break;
    }
    this.setState({
      gameStatus: gameStatus,
    });
  }

  private checkGameStatus(): PairsGameStatus {
    if (this.board.matchedObjectIndices.length < this.board.layout.getWinCount()) {
      return PairsGameStatus.STATUS_IN_PROGRESS;
    }
    return PairsGameStatus.STATUS_COMPLETE;
  }

  private renderBoard() {
    if (!this.board) {
      return;
    }
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const scale = width / 600;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, width, height);

    const gridSize = this.board.level.gridSize;
    const unitSize = width / gridSize;
    const lineWidth = scale * 2;

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);

    ctx.strokeStyle = mediumGray;
    ctx.fillStyle = colorGray;
    ctx.lineWidth = lineWidth;
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        const index = i * gridSize + j;
        if ((this.board.matchedObjectIndices.indexOf(this.board.layout.board[index]) >= 0) || index === this.state.focusIndex1 || index === this.state.focusIndex2) {
          const object = PAIRS_OBJECTS[this.board.layout.board[index]];
          ctx.drawImage(this.imageCache.get(object), unitSize * j, unitSize * i, unitSize, unitSize);
        } else {
          ctx.fillRect(unitSize * j, unitSize * i, unitSize, unitSize);
          ctx.strokeRect(unitSize * j, unitSize * i, unitSize, unitSize);
        }
      }
    }
    const outerLineWidth = scale * 4;
    ctx.strokeStyle = "black";
    ctx.lineWidth = outerLineWidth;
    ctx.strokeRect(outerLineWidth / 2, outerLineWidth / 2, width - outerLineWidth, height - outerLineWidth);
  }
}