import {
  FullscreenDialogWithTitleFragment,
  FullscreenDialogWithTitleFragmentProps,
  FullscreenDialogWithTitleFragmentState
} from "../../shared/FullscreenDialogWithTitleFragment";
import {StyledBoxRow, StyledContainer} from "../../shared/StyledComponents";
import {Box, Divider} from "@mui/material";
import {ReactElement} from "react";

export type BaseHistoryFragmentProps = FullscreenDialogWithTitleFragmentProps & {}

type BaseHistoryFragmentState<T> = FullscreenDialogWithTitleFragmentState & {
  items: T[],
}

export abstract class BaseHistoryFragment<T> extends FullscreenDialogWithTitleFragment<BaseHistoryFragmentProps, BaseHistoryFragmentState<T>> {

  getTitle(): string {
    return "History";
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      items: await this.getItems(),
    });
  }

  abstract getItems(): Promise<T[]>;

  abstract renderItem(item: T): ReactElement;

  renderContent() {
    return <StyledContainer className="hidescroll" style={{overflowY: "scroll"}}>
      <Box style={{display: "flex", flexDirection: "column"}}>
        {this.state.items?.map((item, index) => {
          return <Box style={{display: "flex", flexDirection: "column"}}>
            {index > 0 ? <Divider/> : null}
            <StyledBoxRow style={{height: 72, alignItems: "center"}}>
              {this.renderItem(item)}
            </StyledBoxRow>
          </Box>;
        })}
      </Box>
    </StyledContainer>
  }
}