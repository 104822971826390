import {PureComponent, ReactElement} from "react";
import {Button, Card, Typography} from "@mui/material";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "../shared/StyledComponents";
import {PD_MD} from "../shared/dimens";

export type InfoCardProps = {
  variant?: "default" | "contained",
  button?: boolean,
  onClick?: () => void,
  title?: string,
  renderToolbar?: () => ReactElement,
  children?: any,
}

const VARIANT_STYLES = new Map<string, any>([
  ["default", {}],
  ["contained", {
    background: "#eee",
  }],
]);

export class InfoCard extends PureComponent<InfoCardProps> {

  render() {
    const toolbar = this.props.renderToolbar?.();
    const content = <StyledBoxColumn style={{padding: PD_MD, alignItems: this.props.button ? "flex-start" : "stretch"}}>
      {this.props.title || toolbar
        ? <StyledBoxRow style={{height: 48, alignItems: "center"}}>
          <Typography variant="h6">{this.props.title}</Typography>
          <StyledSpan/>
          {toolbar}
        </StyledBoxRow>
        : null}
      {this.props.children}
    </StyledBoxColumn>;
    return this.props.button ?
      <Button style={{justifyContent: "stretch"}} onClick={() => this.props.onClick()}>{content}</Button> :
      <Card style={{...VARIANT_STYLES.get(this.props.variant || "default")}}>{content}</Card>
  }
}