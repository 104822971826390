import {DIVIDER, PD_MD, SZ_LG, SZ_MD} from "./dimens";
import React, {ReactElement} from "react";
import {Box, Button, Typography} from "@mui/material";
import {BaseApp} from "./BaseApp";
import {PageFragment, PageFragmentProps, PageFragmentState} from "./PageFragment";

export interface FullscreenDialogWithTitleFragmentListener {
  onBackPressed(): boolean;
}

export type FullscreenDialogWithTitleFragmentProps = PageFragmentProps & {
  listener?: FullscreenDialogWithTitleFragmentListener,
}

export type FullscreenDialogWithTitleFragmentState = PageFragmentState & {
  title: string,
}

export abstract class FullscreenDialogWithTitleFragment<P extends FullscreenDialogWithTitleFragmentProps = FullscreenDialogWithTitleFragmentProps, S extends FullscreenDialogWithTitleFragmentState = FullscreenDialogWithTitleFragmentState> extends PageFragment<P, S> {

  protected getCustomTitle(): ReactElement {
    return null;
  }

  protected renderContainerContent(): ReactElement | null {
    const customTitle = this.getCustomTitle();
    return <Box className="footer-tab-fragment-header"
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  position: "relative",
                  paddingBottom: SZ_LG
                }}>
      <Box style={{
        display: "flex",
        flexShrink: 0,
        height: SZ_MD,
        alignItems: "center",
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
        gap: PD_MD,
        left: 0,
        right: 0,
        background: "white",
        borderBottom: DIVIDER,
        position: "fixed",
        zIndex: 1,
      }}>
        <Button onClick={() => this.onGoBack()}>Back</Button>
      </Box>
      {this.state.title || customTitle
        ? <Box className="footer-tab-fragment-header" style={{
          display: "flex",
          flexShrink: 0,
          height: SZ_MD,
          alignItems: "center",
          paddingLeft: PD_MD,
          paddingRight: PD_MD,
          gap: PD_MD,
          pointerEvents: "none",
          position: "fixed",
          zIndex: 2,
          left: 0,
          right: 0,
        }}>
          <Box style={{width: "100%", display: "flex", justifyContent: "center"}}>
            {customTitle ||
              <Typography style={{fontFamily: "Gabarito, sans-serif"}} variant="h4">{this.state.title}</Typography>}
          </Box>
        </Box>
        : null}
      <Box style={{display: "flex", flexDirection: "column", paddingTop: SZ_MD, flexGrow: 1, overflowY: "scroll"}}>
        {this.renderContent()}
      </Box>
    </Box>;
  }

  protected onGoBack() {
    this.props.listener?.onBackPressed() || BaseApp.CONTEXT.hideDialog();
  }

  abstract renderContent();
}